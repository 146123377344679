<template>
<v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12" >{{GetEjecucion }}
  <!-- formulario  -->
  <v-form ref="formProducto" v-model="valid" lazy-validation>
      <v-row no-gutters >
        <v-col class="pa-0 ma-0 py-0 my-0 pt-1 "  cols="12" md="2" sm="4"  >
          <div v-show="activoF7==false">
          <v-combobox ref="RefComboxBuscaProduc"  autocomplete="off" background-color="white" class="fontSize13" hide-details
                        color="grey darken-4" dense outlined label="Codigo:" :items="Rproducto" item-text="codigoProducto" id="formdisabledInput"
                        style="font-size: 13px;" v-model="buscarProduc" @blur="SelctCbBuscarProduc"
                        :rules="[$rules.required]"
                         @keyup="CbFilProducto" v-on:change="changeSelectProduc" 
                         :disabled="!modificando">
                         <template slot="item" slot-scope="item">
                            <span class="fontSize13" >{{item.item.productos.descripcion}} - <b> {{item.item.unidades.descripcion}}</b></span>
                            <span style="display:none;">
                              {{item.item.codigoProducto=GetCodigoProdUni(item.item)}}
                            </span>
                          </template>
                          
                        <template v-slot:prepend>
                          <i @click="SeachProducto" style="font-size: 25px;" class="fa fa-search"></i>
                              </template>
                        <!-- margin-top="50px"style="font-size: 14px;" -->

          </v-combobox> 
          </div>

          <div v-show="activoF7==true">
            <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <!--@change="getChangeClienteBusqueda"  @keyup="CbFilVendedorFactura" -->
                    <v-combobox ref="RefTextFieldBuscaVendedor" v-on="on" autocomplete="off" background-color="white"
                      color="grey darken-4" dense outlined label="buscar Vendedor:" :items="RVendedor" item-text="nombre"
                      style="font-size: 13px;" v-model="vendedorSelect" @blur="SelctCbVendedor"
                      @keyup.enter="SalvarVendedores" @focus="cargarVendedor" hide-details
                      >
                      <template v-slot:prepend-inner>
                        <i style="font-size: 25px;" class="fa fa-universal-access"></i>
                      </template>
                      <!-- margin-top="50px"style="font-size: 14px;" -->
                      <template slot="item" slot-scope="item">
                        <!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
                        <v-col style="border: 1px solid #000000; font-size: 13px; " class="pa-0 ma-0 py-0 my-0" cols="12"
                          md="12" sm="12">
                          <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>NOMBRE:
                              {{ item.item.nombre }}</b></p>

                          <span class="pa-0 ma-0 py-0 my-0">
                            <span style="border: 1px dashed  #616161;"><b>TEL.: </b>{{ item.item.telefono }} </span>
                            <span style="border: 1px dashed  #616161;"><b>CED/RNC: </b> {{ item.item.cedrnc }}</span>
                          </span>
                          <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>DIRECCIÓN: </b>
                            {{ item.item.direccion }} </p>
                          <span style="border: 1px dashed  #616161;"
                            v-if="item.item.hasOwnProperty('ars') && item.item.ars != null"><b> ARS
                              :{{ item.item.ars.nombre }}</b></span>
                          <span style="border: 1px dashed  #616161;" v-if="item.item.diascredito > 0"><b> TIPO: Cliente
                              Credito</b></span>
                          <span style="border: 1px dashed  #616161;" v-if="item.item.diascredito == 0"><b>TIPO: Cliente
                              Contado</b></span>
                        </v-col>


                      </template>
                    </v-combobox>
                  </template>
                  <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                    <span> Se selecciona el Vendedor que desea Asignar a la factura.</span>
                  </i>
            </v-tooltip>
          </div>

        </v-col>
        <v-col class="pa-0 ma-0 py-0 my-0 pt-1 "  cols="12" md="4" sm="5">
          <v-text-field label="* Descripción producto:" dense outlined autocomplete="off" hide-details
                          id="formdisabledInput" :disabled="!productoSeleccionado.pedirNombre" class="fontSize13" v-model="productoSeleccionado.descripcion"
                          >
                          <template v-slot:prepend>
                            <i style="font-size: 15px;" class="fa fa-font"></i>
                          </template>
          </v-text-field>
        </v-col>
        <v-col  class="pa-0 ma-0 py-0 my-0 pt-1 "  cols="12" md="1" sm="3"  >
          
          <VueAutonumeric label="Cantidad" ref="RefTextFieldProductoCantidad" dense outlined hide-details
                          autocomplete="off" @keydown.enter="AddProducto"
                           :rules="[$rules.NumNocero]" v-on:blur="verficardatos"
                          v-model.number="productoSeleccionado.cantidad" style="font-size:13px;"
                          @focus="FocusProductoCantidad"
                          @keydown="keyupEvent" 
                          id="formdisabledInput" :disabled="!modificando">
                          <template v-slot:prepend>
                            <i style="font-size: 15px;" class="fa fa-list-ol"></i>
                          </template>
          </VueAutonumeric>
        </v-col>
        <v-col  class="pa-0 ma-0 py-0 my-0 pt-1 "  cols="12" md="2" sm="4"  >
          
          <VueAutonumeric label="Precio" ref="RefTextFieldProductoPrecio" dense outlined hide-details
                          @keydown.enter="AddProducto" autocomplete="off" :rules="[$rules.required]"
                          v-model.number="productoSeleccionado.precio" v-on:blur="verficardatos" style="font-size:13px;"
                          @focus="FocusProductoPrecio" @blur="blurPrecioProductoSelecc"
                          @input="productoSeleccionado.precioDig=productoSeleccionado.precio" 
                          @keydown="keyupEvent"
                          id="formdisabledInput" :disabled="!modificando">
                          <template v-slot:prepend>
                            <i style="font-size: 18px;" class="fa fa-usd"></i>
                          </template>
          </VueAutonumeric>
        </v-col>
        <v-col  class="pa-0 ma-0 py-0 my-0 pt-1 "  cols="12" md="2" sm="5"  >
          <VueAutonumeric label="Descuento" ref="RefTextFieldProductoDescuento" dense outlined hide-details
                           @keydown.enter="AddProducto" autocomplete="off" :rules="[$rules.Numericc]"
                           v-on:keydown="KeydownTabDescuento"
                           v-model.number="productoSeleccionado.descuentoKeys" @blur="verficardatos"
                          class="fontSize13" @focus="FocusProductoDescuento"
                          @keydown="keyupEvent"
                          id="formdisabledInput" :disabled="!modificando">
                          <template v-slot:prepend>
                            <i v-if="productoSeleccionado.ValorPorc == true" style="font-size: 15px;"
                              class="fa fa-usd"></i>
                            <i v-if="productoSeleccionado.ValorPorc == false" style="font-size: 15px;"
                              class="fa fa-percent"></i>
                          </template>

                          <template v-slot:append-outer>

<v-radio-group  dense hide-details @change=""  style="margin-top: -18%;"
  v-on:change="" v-model="productoSeleccionado.ValorPorc" column>
  <v-radio class="pa-0 ma-0 py-0 my-0 " label="Valor" color="teal lighten-2" :value="true"></v-radio>
  <v-radio class="pa-0 ma-0 py-0 my-0 " label="Porciento" color="teal lighten-2" :value="false"></v-radio>
</v-radio-group>

</template>
                        </VueAutonumeric>
        </v-col>
        <v-col  class="pa-0 ma-0 py-0 my-0 pt-1 "  cols="12" md="1" sm="1" >
          <center>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">

                              <v-btn :disabled="!modificando" @click.prevent="AddProducto" v-on="on" color="green" x-small dark >
                                <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                              </v-btn>

                            </template>
                            <span>Agregar producto</span>
                        </v-tooltip>

                        <v-tooltip top v-if="buscarProduc!=null && buscarProduc.hasOwnProperty('unidadesProductosPK')">
                            <template v-slot:activator="{ on }">

                              <v-btn @click.prevent="getExistProdXAlmacen(buscarProduc)" v-on="on" color="yellow accent-4" x-small>
                                <i style="font-size: 20px;" class="fa fa-eye"></i>
                              </v-btn>

                            </template>
                            <span>Ver Existencia</span>
                        </v-tooltip>
         </center>
        </v-col>
      </v-row>  
  </v-form>
  <!-- fn formulario  -->
    <!-- Alerta  -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="aalert.timeout" :right="aalert.right" :top="aalert.top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- fn Alerta -->
    <!---------------------  Panel Menu Opcion Botones ------------------------------->
    <div style="max-height:300px;" class="overflow-x-auto" v-show="PanelMenuOpcionBotones">
              <!-- columna  ---------------------->
              <PvMenuOpcionBotones ref="RefPvMenuOpcionBotones" @DataRetornAccion="DataRetornMenuOpcBotonesPermisosPv"
                @DataRetornAccionPadre="DataRetornPadrePermisoPv" :cajaSelect="cajaSelect" />
    </div>
    <!--------------------- fn Panel Menu Opcion Botones ------------------------------->

    <!-- ------------------------------------------------------ Modal- Existencia producto -->
    <v-dialog transition="fab-transition" fullscreen label="Modal Existencia producto" v-model="dialogExistenciaProducto" persistent max-width="100%"
      max-height="100%" >
      <div>
        
        <v-card color="transparent">
          <!-- columna informacion Existencia producto -->
          <center>
          <v-col cols="12" md="5" sm="12">
            <!-- <v-container> -->
              <v-card>
                <!-- <b :class="`d-flex justify-center`"> </b> -->
              <v-toolbar dark color="#dd4b39" dense>
              <v-tooltip bottom>
              <template v-slot:activator="{ on }">
              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> EXISTENCIA DE PRODUCTO
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> EXISTENCIA DE PRODUCTO</span></i>

              </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>
            <v-col class="pa-0 ma-0 py-0 my-0 d-flex justify-end" cols="12" md="12" sm="12">
              <v-btn color="grey darken-4" fab x-small @click="dialogExistenciaProducto = false">
                <i style="font-size: 20px; color: white" class="fa fa-close"></i>
              </v-btn>
            </v-col>
            </v-btn-toggle>
          </v-card-actions>

          <!-- <v-btn
              color="grey darken-4"
              fab
              small
              @click=""
            >
             <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
            </v-btn> -->
              </v-toolbar>
                <!-- Existencia de productos almacen  -->
            <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 pt-1">
              <div style="max-height:300px;" class="overflow-x-auto">
                <b>{{descProductoExistencia}}</b>
                <v-card v-if="UsarLote == false" style="border: 1px solid  #616161;">
                  <v-data-table :headers="[
                    { text: 'ALMACEN', value: 'almacen', sortable: false, class: ['no-gutters black--text'] },
                    { text: 'EXISTENCIA', value: 'existencion', sortable: false, class: ['no-gutters black--text'] },
                  ]" dense :items="ListaProdxAlmacen" :footer-props="arrayPropiedases" :items-per-page="15">

                    <template v-slot:item.almacen="props">
                      <span style="font-size:13px;">{{ props.item.almacen }}</span>
                    </template>

                    <template v-slot:item.existencion="props">
                      <span style="font-size:13px;">{{ props.item.existencion }}</span>
                    </template>

                  </v-data-table>
                </v-card>
                <v-card v-if="UsarLote == true" style="border: 1px solid  #616161;">
                  <v-data-table v-model="selectedListProdxLote" item-key="indx" :show-select="true" :single-select="true"
                    :headers="[
                      { text: 'CÓDIGO', value: 'fechaVencimiento.codigo', sortable: false, class: ['no-gutters black--text'] },
                      { text: 'FECHA VENCIMIENTO', value: 'fechaVencimiento', sortable: false, class: ['no-gutters black--text'] },
                      { text: 'EXISTENCIA', value: 'balance', sortable: false, class: ['no-gutters black--text'] },
                    ]" dense :items="ListaProdxLote" :footer-props="arrayPropiedases" :items-per-page="15"
                    @item-selected="obtenerDatoSelectProdxLote" @click:row="obtenerDatoSelectProdxLoteClickRow">
                    <template v-slot:item.balance="props">
                      <span style="font-size:13px;">{{ props.item.balance }}</span>
                    </template>

                    <template v-slot:item.fechaVencimiento="props">
                      <span style="display:none;">{{ props.item.indx = ListaProdxLote.indexOf(props.item) }}</span>

                      <span style="font-size:13px;" v-if="props.item.fechaVencimiento != null">{{
                        FormatoFecha(props.item.fechaVencimiento.fecha) }}</span>

                    </template>

                  </v-data-table>
                </v-card>


              </div>
            </v-col>
            <!-- bn Existencia de productos almacen  -->
              </v-card>
            <!-- </v-container> -->
          </v-col>
          <!-- columna informacion Existencia producto -->
        </center>
        </v-card>

      </div>
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- Existencia producto-->




</v-col>
</template>
<script>
import { EventBus } from "../../event-bus";
import { currencyFormatter, FormatoFecha, HoraFormatter,
  RedondeoValorCantRed,
  currencyFormatterCant,RedondeoValor,
  RedondeoBasico,
  fechaNueva,
  getParamFecha,
  validaCedRnc} from "@/js/Funciones.js";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import PvMenuOpcionBotones from "@/PuntodeVenta/UtilidadComponents/PvMenuOpcionBotones.vue";
export default {
  components: {
    VueAutonumeric,
    PvMenuOpcionBotones
  },
  created() { 
  },
  mounted() {
  this.ConfImpfiscal = this.$store.getters.GetConfiguracionGeneral.config.impresoraFiscal
  this.IncluirItbisGeneral = this.$store.getters.GetConfiguracionGeneral.configPv.impuestoincluido;
  this.UsarLote = this.$store.getters.GetConfiguracionGeneral.configInv.usarLote
},
  beforeDestroy() {

  },
 
  data: () => ({ 
    arrayPropiedases: {
      'items-per-page-options': [10, 15, 20, 50],
      'items-per-page-text': 'Cantidad de Registros Por Paginas',
      showFirstLastPage: false
 },
 vendedorSelect:null,
   RVendedor:[],
   activoF7:false,
   editando:false,
   modificando:false,
    valid: true,
    PanelMenuOpcionBotones:false,
    EjecutarPermisoPv:false,
    ConfImpfiscal: false,
    IncluirItbisGeneral:false,
    buscarProduc:null,
    Rproducto:[],
   domiciliofactura:null,
    Facturasventa:{tarjeta:0},
    productoSeleccionado:{
      descripcion:'',
      ValorPorc:false,
      descuentoKeys:0,
      cantidad:1,
      precio:0,
      pedirNombre:false,
      vendedor: null,
    },
    Totales: {
      ReciboIngreso: {
        abono: 0,
        retencion: 0,
        descuento: 0,
        isr: 0,
        pendiente: 0,
      },

      totalGeneral: {
        subtotal: 0,
        itbis: 0,
        total: 0,
        descuento: 0,
        Retencion: 0,
        recargo: 0,
        cobertura: 0,
      },

      CierresCajas: {
        totalTotal: 0,
        totalDenomiacionvalor: 0,
      },
    },

    ListaProdxAlmacen:[],
    ListaProdxLote:[],
    descProductoExistencia:'',
    dialogExistenciaProducto:false,
    UsarLote:false,
    aalert: {
      estado: false,
      color: null,
      timeout: 3000,
      top: false,
      right: false
    },

  }),

  props: { 

    cajaSelect: {
      required: false,
      type: Object,
      default: null
    },
    configuraciones: {
      required: false,
      type: Object,
      default: null
    },

    headerFactura: {
      required: false,
      type: Object,
      default: null
    },
    totalGeneralFooter: {
      required: false,
      type: Object,
      default: null
    },
    // salvarNuevo,editar,modificar:
modofactura: {
  required: false,
  type: String,
  default: "salvarNuevo"
}
   
  },
  methods: { 
  currencyFormatter, 
  FormatoFecha, 
  HoraFormatter,
  RedondeoValorCantRed,
  currencyFormatterCant,
  RedondeoValor,
  RedondeoBasico,
  fechaNueva,
  getParamFecha,
  validaCedRnc,
    nuevoProducto(){
      this.buscarProduc=null
      this.productoSeleccionado={
      descripcion:'',
      ValorPorc:false,
      descuentoKeys:0,
      cantidad:1,
      precio:0,
      pedirNombre:false,
      precioDig:0,
      precioOrig:0,
      valorimpuesto:0,
      valordescuento:0,
      omitirRegla:false,
      paramEspecial:false,
      paramOfert:false,
      vendedor:null
    }
    //this.RedireccionFocus('RefComboxBuscaProduc')

    },

    CbFilProducto(e){
      if(e.keyCode == 13){
        this.Rproducto=[]
      if (e.target.value.trim().length == 0) {
        return;
      }
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/unidades-productos/" +
          e.target.value.trim().replaceAll('/', '*-S-*') +
          "/false/" + this.cajaSelect.sucursal.codigo + "/buscar",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
        this.Rproducto = res.data
        });
    }

    if (e.keyCode == 118) {
         e.preventDefault();
        this.activoF7=true
        var _this = this;
        setTimeout(function () {
          _this.$refs.RefTextFieldBuscaVendedor.focus();
        }, 500);
        return;
      }

    
    },
    keyupEvent(e){
      if (e.keyCode == 118) {
         e.preventDefault();
        this.activoF7=true
        var _this = this;
        setTimeout(function () {
          _this.$refs.RefTextFieldBuscaVendedor.focus();
        }, 500);
        return;
      }
    },

   SeachProducto(){
    //ref="RefComboxBuscaProduc"
    if(this.$refs.RefComboxBuscaProduc.internalSearch!=null){
      
      this.RedireccionBlur('RefComboxBuscaProduc')
      var VALUE=this.$refs.RefComboxBuscaProduc.internalSearch.trim()
      var obj={
        keyCode:13,
        target:{
          value:VALUE
        }
      }

     this.CbFilProducto(obj)
      var _this = this;
        setTimeout(function () {
          _this.RedireccionFocus('RefComboxBuscaProduc') 
          _this.buscarProduc=""
        }, 900);
      var _this = this;
        setTimeout(function () {
          //  _this.CbFilProducto(obj)
          //  _this.$refs.RefComboxBuscaProduc.virtualizedItems=_this.Rproducto
        }, 1000);
     
    }
   },

    GetCodigoProdUni(unidaproducto) {
      if (this.$store.getters.GetConfiguracionGeneral.configInv.usarLote) {
        if(unidaproducto.lote!==""){
          // console.log(unidaproducto.lote.substring(10))
          this.lote = parseInt(unidaproducto.lote.substring(10)); 
        }
        return unidaproducto.lote;
      } else {
        var pk = unidaproducto.unidadesProductosPK;
        var codigoP = ('000000' + pk.producto).substring(('000000' + pk.producto).length - 6, ('000000' + pk.producto).length)
        var codigoU = ('0000' + pk.unidad).substring(('0000' + pk.unidad).length - 4, ('0000' + pk.unidad).length)
        return codigoP + codigoU;
      }
    },

    SelctCbBuscarProduc() {
var _this = this;
setTimeout(function () {
  if (_this.buscarProduc == null) {
    _this.buscarProduc = "";
  }

  if (!_this.buscarProduc.hasOwnProperty('unidadesProductosPK')) {
    _this.buscarProduc = "";
    _this.buscarProduc = null;
    _this.nuevoProducto()
    return;
  }
}, 300);

    },

    async changeSelectProduc(e){

      if(e!=null && e.hasOwnProperty("unidadesProductosPK") ){
        var OBJ =JSON.parse(JSON.stringify(e))

        if(this.headerFactura!=null){
          OBJ.ValorPorc=this.headerFactura.ValorPorc
          if(this.headerFactura.ValorPorc==true){
            OBJ.descuentoKeys=this.headerFactura.descuento
            OBJ.valordescuento=this.headerFactura.descuento
          }else{
            OBJ.descuentoKeys=this.headerFactura.descuento
            OBJ.descuento=this.headerFactura.descuento
          }
        }else{
          OBJ.ValorPorc=false
          OBJ.descuentoKeys=0
          OBJ.descuento=0
        }
        OBJ.descripcion=`${e.productos.descripcion.trim()} ${e.unidades.descripcion.trim()}` 
        OBJ.cantidad=e.hasOwnProperty("cantidad") && e.cantidad != null ? e.cantidad : 1;
        OBJ.precio=e.precio
        OBJ.precioDig=e.precio
        OBJ.precioOrig=e.precio
        OBJ.pedirNombre=e.productos.pedirNombre
        OBJ.valorimpuesto=0
        OBJ.valordescuento=0
        OBJ.omitirRegla=false
        OBJ.paramEspecial=false
        OBJ.paramOfert=false
        OBJ.productoValidadoEspOfer=false
        OBJ.vendedor=null
        this.productoSeleccionado=JSON.parse(JSON.stringify(OBJ))
      
        this.RedireccionFocus('RefTextFieldProductoCantidad')
      
      }
      //  OBJ.ValorPorc
    },

    verficarDatosProducto(producto){
      return  new Promise((resolve, reject) => {
        var productoSeleccionado= JSON.parse(JSON.stringify(producto))
        if (productoSeleccionado.productos != null) {
        var Cant = productoSeleccionado.cantidad
        var PrecioVal = 0

        if (this.ConfImpfiscal == true) {
          PrecioVal = productoSeleccionado.precio
        } else {

          if (productoSeleccionado.productos.incluirimpuestoenprecio == true) {
            if (productoSeleccionado.productos.impuesto != null) {
              PrecioVal = (productoSeleccionado.precio) / (1 + (productoSeleccionado.productos.impuesto.tasa / 100))
            } else { PrecioVal = productoSeleccionado.precio }
          } else {
            PrecioVal = productoSeleccionado.precio
          }

        }

        var DescPorencima = false
        var SuperAdmin = this.$store.getters.GetdatosInfUsu.usuario.sudo
        if (this.$store.getters.GetdatosInfUsu.usuario.usuarioPermisoPvList.length > 0) {
          var ObjPerm = this.$store.getters.GetdatosInfUsu.usuario.usuarioPermisoPvList.filter((ee) => ee.fn == 3 && ee.op == 14);
          if (ObjPerm.length > 0) {
            DescPorencima = ObjPerm[0].permiso
          } else {
            DescPorencima = false
          }
        }

        if (productoSeleccionado.ValorPorc == true) {
          //convirtiendo en porciento
          var valorDesc = ((productoSeleccionado.descuentoKeys * 100) / (PrecioVal * Cant));
          var DescOdeMax = ((productoSeleccionado.descuentoKeys * 100) / (PrecioVal * Cant));
          productoSeleccionado.descuento = JSON.parse(JSON.stringify(productoSeleccionado.descuentoKeys))

          if (productoSeleccionado.paramEspecial == false) {
            if (SuperAdmin == false && DescPorencima == false) {
              if (valorDesc > productoSeleccionado.productos.descuentomaximo) {
                var valorrr = ((productoSeleccionado.productos.descuentomaximo * (PrecioVal * Cant)) / 100)
                productoSeleccionado.descuento = valorrr
                productoSeleccionado.descuentoKeys = valorrr
                DescOdeMax = productoSeleccionado.productos.descuentomaximo
              }
            }
          }

          productoSeleccionado.DescValor = productoSeleccionado.descuento
          productoSeleccionado.valordescuento = productoSeleccionado.descuento
          productoSeleccionado.descuento = DescOdeMax
        } else {
          productoSeleccionado.descuento = JSON.parse(JSON.stringify(productoSeleccionado.descuentoKeys))

          if (productoSeleccionado.paramEspecial == false) {
            if (SuperAdmin == false && DescPorencima == false) {
              if (productoSeleccionado.descuento > productoSeleccionado.productos.descuentomaximo) {
                productoSeleccionado.descuento = productoSeleccionado.productos.descuentomaximo
                productoSeleccionado.descuentoKeys = productoSeleccionado.productos.descuentomaximo
              }
            }
          }
          //convirtiendo en valor
          productoSeleccionado.DescValor = ((productoSeleccionado.descuento * (PrecioVal * Cant)) / 100)
          productoSeleccionado.valordescuento = ((productoSeleccionado.descuento * (PrecioVal * Cant)) / 100)
        }

        if (productoSeleccionado.ValorPorcRecarg == true) {
          productoSeleccionado.RecarValor = productoSeleccionado.recargo
        } else {
          productoSeleccionado.RecarValor = ((productoSeleccionado.recargo * (productoSeleccionado.precio * Cant)) / 100)
        }

      }

          resolve(productoSeleccionado)
        // var _this=this;
        // setTimeout(function(){ resolve(productoSeleccionado) }, 300);
          
        });

    },

    // Verificando si el producto tiene descuento y si lo tiene es verificando si el descuento es mayor
    // al descuento máximo permitido para el producto.
    async verficardatos(){
    const result =  await this.verficarDatosProducto(this.productoSeleccionado);
    var _this=this;
    setTimeout(function(){ _this.productoSeleccionado=JSON.parse(JSON.stringify(result)) }, 300);

    },
    
//  Busca el descuento del producto tanto especial como el descuento automatico
    async buscarDescuentoEspecialDecAutom(producto){
      var ObjProd= JSON.parse(JSON.stringify(producto))
      var cli = 0
        if (this.headerFactura.cliente != null) {
          cli = this.headerFactura.cliente.codigo
        }
        var SoloCxc = this.$store.getters.GetConfiguracionGeneral.configPv.soloCxc
      return new Promise((resolve, reject) => {
        (async () => {

          var link = `/producto/${ObjProd.productos.codigo}/descuento/${SoloCxc}/${this.domiciliofactura != null}/${this.Totales.totalGeneral.cobertura > 0}/${this.Facturasventa.tarjeta > 0}/ ${this.RedondeoBasico(this.totalGeneralFooter.subtotal + ObjProd.precio)}/${cli}`
        this.$axios
          .get(this.$hostname +this.$hName +link,
            {headers: this.$store.getters.GetheadersAxios })
          .then(res => {
           if(res.data.descuento>0){
           if(res.data.especial==true){
            (async () => {
            var DESCUENTO_APLICAR=0
            //esto es por si cancelan el descuento que se le aplica al cliente
            if(this.headerFactura.descuento>0){
              if(this.headerFactura.ValorPorc==false){
                  if(res.data.descuento< this.headerFactura.descuento){
                    DESCUENTO_APLICAR=this.headerFactura.descuento
                  } else{DESCUENTO_APLICAR=res.data.descuento } 
              }else{
                 var OBJPRO=JSON.parse(JSON.stringify(producto))
                 OBJPRO.descuentoKeys=this.headerFactura.descuento
                 OBJPRO.ValorPorc=this.headerFactura.ValorPorc
                 const resultDesc =  await this.verficarDatosProducto(OBJPRO);
                 if(res.data.descuento< this.RedondeoBasico(resultDesc.descuento)){
                    DESCUENTO_APLICAR=this.RedondeoBasico(resultDesc.descuento)
                  } else{DESCUENTO_APLICAR=res.data.descuento } 
    
              }
            }else{DESCUENTO_APLICAR=res.data.descuento}
           
            if(producto.hasOwnProperty("descuDefoul")){ 
              DESCUENTO_APLICAR=res.data.descuento
              delete ObjProd["descuDefoul"]

             }
            ObjProd.descuentoKeys=DESCUENTO_APLICAR
            ObjProd.descuento=DESCUENTO_APLICAR
            ObjProd.paramEspecial=res.data.especial
            ObjProd.ValorPorc=false

          })() 
           }else{
            if(!producto.hasOwnProperty("editando")){
            ObjProd.descuentoKeys=res.data.descuento 
            ObjProd.descuento= res.data.descuento 
            ObjProd.paramEspecial=res.data.especial
            ObjProd.ValorPorc=false
            }else{
              if(ObjProd.descuentoKeys==0){
                ObjProd.descuentoKeys=res.data.descuento 
                ObjProd.descuento=res.data.descuento 
                ObjProd.paramEspecial=res.data.especial
                ObjProd.ValorPorc=false
              }
            }
           } 
           }
          /* var tasaMoneda = JSON.parse(JSON.stringify(this.headerFactura.moneda.tasa));
           if(ObjProd.descuento>0){
            ObjProd.descuentoKeys=this.RedondeoValor(res.data.descuento /tasaMoneda)
            ObjProd.descuento=this.RedondeoValor(res.data.descuento/tasaMoneda) 
            ObjProd.valordescuento=this.RedondeoValor(res.data.valordescuento /tasaMoneda)
           }*/

            resolve(ObjProd)
           /* this.productoSeleccionado.descuento = res.data.descuento
            this.productoSeleccionado.descuentoKeys = res.data.descuento
            this.productoSeleccionado.paramEspecial = res.data.especial*/
          })
          .catch(error => { console.log('-E-:' + error); return; }
          );

        })()
      });

   },
   
    async verificarValidarCalcularVIDProducto(producto){
      // var element= JSON.parse(JSON.stringify(producto))
      return new Promise((resolve, reject) => {
        (async () => {
          const result0 =  await this.buscarDescuentoEspecialDecAutom(producto);
          const result1 =  await this.verficarDatosProducto(result0);
           const result11 =  await this.buscarListaPrecioAutom(result1);
           const result2 =  await this.ValidarProductoOpcionVer(result11);
          const result3 =  await this.CalcularValorImpuestoDescuento(result2);
          resolve(result3)
        })()
      });
    },

    async AddProducto(){
  if (this.$refs.formProducto.validate()) {
     const result1 =JSON.parse(JSON.stringify(this.productoSeleccionado))
     if(this.vendedorSelect!=null&&this.vendedorSelect.hasOwnProperty('codigo')){
      result1.vendedor=this.vendedorSelect
      this.vendedorSelect=null
     }

    // const result1 =  await this.verificarValidarCalcularVIDProducto(this.productoSeleccionado);
    var _this=this;
    setTimeout(function(){ 
      _this.$emit("DataRetornAgregarProducto", result1)
     }, 300);


      // this.$emit("DataRetornAgregarProducto", this.productoSeleccionado)
      // this.nuevoProducto()
} else {
this.alerta("los campos en rojo son requeridos", "error");
}
    },

     // Llamar a una función en un componente secundario para los permisos de usuario.
    funcionValidaPermisoPv(e) {
        var Sudo = this.$store.getters.GetdatosInfUsu.usuario.sudo
        var permiso = this.$store.getters.GetdatosInfUsu.usuario.usuarioPermisoPvList

        //if (Sudo == false) { }
        var obj = {
          accion: JSON.parse(JSON.stringify(e)),
          Permiso: permiso,
          Sudo: Sudo
        }
        this.$refs.RefPvMenuOpcionBotones.ClickAccionPadreEjecut(obj)
    },
    DataRetornPadrePermisoPv(e) {
      //Permite Cambiar Cantidad a producto"
      if (e == 'AccCambioCantidadProd') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.$refs.RefTextFieldProductoCantidad.focus();
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }
      //Permite Cambiar Precio a producto"
      if (e == 'AccCambioPrecioProd') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.$refs.RefTextFieldProductoPrecio.focus();
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }
      //Permite Cambiar Descuento a producto"
      if (e == 'AccCambiarDescuentoProd') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.$refs.RefTextFieldProductoDescuento.focus();
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }

      //Permite Cambiar vendedor a producto"
      if (e == 'AccCambiarVendedorProd') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.$refs.RefTextFieldProductoVendedor.focus();
          _this.cargarVendedorProducto()
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }

      //Permite Cambiar vendedor a producto"
      if (e == 'AccEliminarLineaProd') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.eliminarInfProductoCompra();
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }

      //Permite Cambiar recargo a producto"
      if (e == 'AccCambiarRecargoProd') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.$refs.RefTextFieldProductoRecargo.focus();
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }

      //Permite Crear Cliente"
      if (e == 'AccCrearEditarCliente') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.SalvarCrearEditarCliente();
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }

      //Cancelar Creacion de Factura
      if (e == 'AccCancelarCreacionFactura') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.limpiarPuntoVentaCompleto();
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }


      //Abrir Caja Registradora F2
      if (e == 'AccAbrirCajaRegistradoraF2') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.openCashDrawer()
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }



      //entraga mensajero domicilio
      if (e == 'AccMensajeroEntregado') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.slvarEntregado()
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }

      // no entraga mensajero domicilio
      if (e == 'AccMensajeroNoEntregado') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.salvarNoEntregado()
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }

      //Anular cambio de divisa
      if (e == 'AccAnularCambioDivisa') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.anularCambioDivisa()
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }

      //imprimir cambio de divisa
      if (e == 'AccImprimirCambioDivisa') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.imprimirCambioDivisa()
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }

      //imprimir Guardar temporal
      if (e == 'AccGuardartemporal') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.OpenDialogGuardartemporal()
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }


      //Aplicar RestaurarFact
      if (e == 'AccAplicarRestaurarFact') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          // _this.AplicarRestaurarFact(_this.ObjEjecPermisoPv)
          _this.verificarusofacturatemporalAplicar(_this.ObjEjecPermisoPv)
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }


      //Aplicar RestaurarFact
      if (e == 'AccImprimirRestaurarFact') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.ImprimirRestaurarFact(_this.ObjEjecPermisoPv)
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }


      //Aplicar RestaurarFact
      if (e == 'AccImprimirRestaurarFact') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.ImprimirRestaurarFact(_this.ObjEjecPermisoPv)
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }


      //Aplicar deleRestaurarFact
      if (e == 'AccDeleRestaurarFact') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.LlamadeleRestaurarFact(_this.ObjEjecPermisoPv)
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }

      if (e == 'AccReimpresionOpcRecibo') {
        this.BtnReimprecionvalue = 1
        this.ReimprecionSheet = false
        this.ReimprecionResetCargarPaginacion()
        return;
      }

      if (e == 'AccReimpresionOpcOtrosingresos') {
        this.BtnReimprecionvalue = 2
        this.ReimprecionSheet = false
        this.ReimprecionResetCargarPaginacion()
        return;
      }

      if (e == 'AccReimpresionOpcFactura') {
        this.BtnReimprecionvalue = 3
        this.ReimprecionSheet = false
        this.ReimprecionResetCargarPaginacion()
        return;
      }

      if (e == 'AccReimpresionOpcDevolución') {
        this.BtnReimprecionvalue = 4
        this.ReimprecionSheet = false
        this.ReimprecionResetCargarPaginacion()
        return;
      }

      if (e == 'AccReimpresionOpcValeCaja') {
        this.BtnReimprecionvalue = 5
        this.ReimprecionSheet = false
        this.ReimprecionResetCargarPaginacion()
        return;
      }

      if (e == 'AccReimpresionOpcCotizacion') {
        this.BtnReimprecionvalue = 6
        this.ReimprecionSheet = false
        this.ReimprecionResetCargarPaginacion()
        return;
      }

      if (e == 'AccReimpresionOpcConduce') {
        this.BtnReimprecionvalue = 7
        this.ReimprecionSheet = false
        this.ReimprecionResetCargarPaginacion()
        return;
      }

if (e == 'AccDescuentoGlobalFactura') {
          this.aplicarDescuentoGlobalFacturaXencimaMaximo()
        return;
      }
    },

    DataRetornMenuOpcBotonesPermisosPv(e) {

    },


      // Comprobando si el usuario tiene permiso para cambiar la cantidad de un producto.
    FocusProductoCantidad() {
      if (this.EjecutarPermisoPv == false) {
        this.funcionValidaPermisoPv('AccCambioCantidadProd')
        return;
      }

    },

    blurPrecioProductoSelecc() {
        var _this=this;
        setTimeout(function(){  
          _this.productoSeleccionado.precioDig=JSON.parse(JSON.stringify(_this.productoSeleccionado.precio));
        }, 300);
    },

    // Comprobar si el usuario tiene permiso para cambiar el precio de un producto.
    FocusProductoPrecio() {
      if (this.EjecutarPermisoPv == false) {
        this.funcionValidaPermisoPv('AccCambioPrecioProd')
        return;
      }

    },

    FocusProductoDescuento() {
      if (this.EjecutarPermisoPv == false) {
        this.funcionValidaPermisoPv('AccCambiarDescuentoProd')
        return;
      }

    },


     // Validando el formulario y luego se está comprobando si el producto es válido o no.
     ValidarProductoOpcionVer(producto) {
      var productoSeleccionado= JSON.parse(JSON.stringify(producto))
      var _this=this;
      return  new Promise((resolve, reject) => {
        // if (this.$refs.formCampProdc.validate()) {
          var Config = this.$store.getters.GetConfiguracionGeneral
        if (Config.configPv.noBajaPrecio == true) {
          if (this.RedondeoValor(producto.precio) < this.RedondeoValor(producto.precioOrig)) {
            productoSeleccionado.precio = JSON.parse(JSON.stringify(producto.precioOrig))
            productoSeleccionado.precioDig = JSON.parse(JSON.stringify(producto.precioOrig))
            producto.precio = JSON.parse(JSON.stringify(producto.precioOrig))
            producto.precioDig = JSON.parse(JSON.stringify(producto.precioOrig))
            if(this.buscarProduc!=null){
              this.productoSeleccionado.precio = JSON.parse(JSON.stringify(producto.precioOrig))
               this.productoSeleccionado.precioDig = JSON.parse(JSON.stringify(producto.precioOrig))
            }
            this.alerta("Este producto no se permite vender por debajo del precio actual", "error");
            return;
          }

        }

        if (Config.configPv.venderalcosto == false) {
          if (this.RedondeoValor(producto.precio) < this.RedondeoValor(producto.costo)) {
            productoSeleccionado.precio = JSON.parse(JSON.stringify(producto.costo))
            productoSeleccionado.precioDig = JSON.parse(JSON.stringify(producto.costo))
            producto.precio = JSON.parse(JSON.stringify(producto.costo))
            producto.precioDig = JSON.parse(JSON.stringify(producto.costo))
            if(this.buscarProduc!=null){
              this.productoSeleccionado.precio = JSON.parse(JSON.stringify(producto.costo))
               this.productoSeleccionado.precioDig = JSON.parse(JSON.stringify(producto.costo))
            }
            this.alerta("Este producto no se permite vender por debajo del costo actual", "error");
            return ;

          }
        }
      if (this.configuraciones.configInv.permitirNegativos == false) {
      if (producto.productos.servicio==false && producto.cantidad > producto.existencias) {
        this.alerta("la cantidad que intenta vender, es mayor a la existencia", "error");
        return;
      }
      }


      // if (e.hasOwnProperty('ProductoValidado') && e.ProductoValidado == true) {
      //   this.SalvarInfProducto()
      // } else {

        if (this.configuraciones.configInv.permitirNegativos == false) {

          var BOOL_OMITI_REGLA=false
          if (productoSeleccionado.hasOwnProperty("omitirRegla") && productoSeleccionado.omitirRegla == true) {
            BOOL_OMITI_REGLA=true
    
           } 

          if ( BOOL_OMITI_REGLA==false && productoSeleccionado.productos.servicio==false && productoSeleccionado.cantidad > productoSeleccionado.existencias) {
            this.alerta("la cantidad que intenta vender, es mayor a la existencia", "error");
            // this.PanelSelectProducto = true
            return;
          }
        }


       /* e.ProductoValidado = true
        this.SalvarInfProducto() */

      // }



    // if (this.ProductoOpcionVer == false) {
    //       this.loading(true)
    //       if (this.EjecutarInfSaveProd == true) {
    //         this.EjecutarInfSaveProd = false
    //         var _this = this;
    //         setTimeout(function () {
    //           _this.preValidadorProducto(e)
    //         }, 400);

    //         setTimeout(function () {
    //           _this.loading(false)
    //         }, 600);
    //       }

    //     } else {
    //       this.loading(true)
    //       if (this.EjecutarInfSaveProd == true) {
    //         this.EjecutarInfSaveProd = false
    //         var _this = this;
    //         setTimeout(function () {
    //           _this.ProductoOpcionVer = false
    //           _this.BtnSelectAddProducto(e)
    //         }, 400);

    //         setTimeout(function () {
    //           _this.loading(false)
    //           _this.EjecutarInfSaveProd = true
    //         }, 600);
    //       }


    //     }


        if (this.UsarLote == true) {
          var FechSel = this.selectedListProdxLote[0].fechaVencimiento.fecha
          var ObjProRow = this.ListaProdxLote.filter((ee) => ee.fechaVencimiento.codigo != e.lote && ee.fechaVencimiento.fecha < FechSel);
          if (ObjProRow.length > 0) {
            this.alerta("Existe un lote más antigüo  que el que sea seleccionado", "error");
          }
        }
      // }
      // else {
      //   this.alerta("los campos en rojo son requeridos", "error");
      //   return 'alerta'
      // }
        resolve(productoSeleccionado)
        });

    },

   // El código anterior define un método llamado "RemoveTax" en un componente de Vue. Este método toma
   // dos parámetros: "prod" y "precio".
    RemoveTax(prod, precio) {
      var pre = 0
      return  new Promise((resolve, reject) => {
        if (prod.incluirimpuestoenprecio == true && prod.impuesto != null) {
        pre = (precio / (1 + (prod.impuesto.tasa / 100)))
      } else {
        pre = precio;
      }
       //var RED3 =this.RedondeoValorCantRed(pre,2)
       var RED3 =this.RedondeoValorCantRed(pre,2)
      // return RED3;
      resolve(RED3)
        });
    }, 
    // El código anterior define una función asíncrona llamada "CalculateTax" en un componente de Vue.
    // Esta función toma un parámetro llamado "producto".
    async CalculateTax(producto) {
      var element= JSON.parse(JSON.stringify(producto))
    return  new Promise((resolve, reject) => {
      (async () => {
//element.precio = this.RedondeoValor(element.precio / tasaMoneda);
if (this.headerFactura.tipocomprobante != null && this.headerFactura.tipocomprobante.itbis == false) {
        // element.precio = this.RemoveTax(element.productos, element.precio); element.precioOrig
        const RE_PRECIO = await this.RemoveTax(element.productos, element.precioDig);
        element.precio = RE_PRECIO;
        element.valor = element.precio * element.cantidad;
        resolve(0.00);
      }
      if (this.ConfImpfiscal == true) {
        resolve(element.cantidad * this.RedondeoValor((element.precio - (element.DescValor / element.cantidad)) - ((element.precio - (element.DescValor / element.cantidad)) / (1 + (element.productos.impuesto.tasa / 100)))))
      } else {
        // var precioVar = this.RemoveTax(element.productos, element.precio) element.precioOrig
        var precioVar = await this.RemoveTax(element.productos, element.precioDig)
        // console.log(`Ite==> ${element.cantidad} * ((${precioVar} - (${element.DescValor} / ${element.cantidad})) * (${element.productos.impuesto.tasa} / 100))`)
       resolve(element.cantidad * this.RedondeoValorCantRed(((precioVar - (element.DescValor / element.cantidad)) * (element.productos.impuesto.tasa / 100)),2))
      }
       })()

        });

    },


    // El código anterior define una función asíncrona llamada "CalculateTax" en un componente de Vue.
    // Esta función toma un parámetro llamado "producto".
    async CalculateTaxPrecioValor(producto) {
      var element= JSON.parse(JSON.stringify(producto))
    return  new Promise((resolve, reject) => {
      (async () => {
//element.precio = this.RedondeoValor(element.precio / tasaMoneda);
if (this.headerFactura.tipocomprobante != null && this.headerFactura.tipocomprobante.itbis == false) {
        // element.precio = this.RemoveTax(element.productos, element.precio); element.precioOrig
        const RE_PRECIO = await this.RemoveTax(element.productos, element.precioDig);
        element.precio = RE_PRECIO;
        element.valor = element.precio * element.cantidad;
        resolve(element);
      }
      if (this.ConfImpfiscal == true) {
        resolve(element)
      } else {

        var precioVar = await this.RemoveTax(element.productos, element.precioDig)
        resolve(element)
      }
       })()

        });

    },


   // El código anterior define una función asíncrona llamada `calcularValorNeto` en un componente de
   // Vue. Esta función toma un parámetro `producto`.
    async calcularValorNeto(producto) {
      var element= JSON.parse(JSON.stringify(producto))
      var VALOR_NETO=0
    return  new Promise((resolve, reject) => {
      (async () => {

if (this.ConfImpfiscal == true) {

if (this.IncluirItbisGeneral == false) {

  VALOR_NETO=this.RedondeoBasico(
    ((element.precioXcantidadNt+element.valorimpuesto)-element.DescValor)
    )
}

if (this.IncluirItbisGeneral == true) {

  VALOR_NETO=this.RedondeoBasico(
    ((element.precioXcantidadNt)-element.DescValor)
    )
}

} else {

  VALOR_NETO=this.RedondeoBasico(
    ((element.precioXcantidadNt+element.valorimpuesto)-element.DescValor)
    )

}

 resolve(VALOR_NETO);
       })()

        });

    },



    async CalcularValorImpuestoDescuento(producto){
      var element= JSON.parse(JSON.stringify(producto))
      // var tasaMoneda = JSON.parse(JSON.stringify(this.headerFactura.moneda.tasa));
      var tasaMoneda = this.$store.getters.GetConfiguracionGeneral.config.monedabase.tasa
      var Ccobertura = 0
      return new Promise((resolve, reject) => {
        (async () => {
          if (!element.hasOwnProperty("paramOfert") || element.paramOfert == false) {
           /* element.precio = this.RedondeoValor(element.precio / tasaMoneda);
            element.valor = this.RedondeoValor(element.precio * element.cantidad);*/
            //precioOrig
             element.precio = this.RedondeoValor(element.precioDig / tasaMoneda);
            element.valor = this.RedondeoValor(element.precio * element.cantidad);
            if (this.ConfImpfiscal == false) {
              if (element.productos.incluirimpuestoenprecio == true) {
                if (element.productos.impuesto != null) {
                  // var precioVar = this.RemoveTax(element.productos, element.precio)
                   var precioVar =  await this.RemoveTax(element.productos, element.precioDig)
                  this.Totales.totalGeneral.subtotal += (element.cantidad * precioVar)
                  element.precioXcantidadNt=this.RedondeoValor((element.cantidad * precioVar)/ tasaMoneda)
                   var VALOR_IMPUESTO= this.RedondeoValor(await this.CalculateTax(element)/tasaMoneda);
                  element.valorimpuesto= VALOR_IMPUESTO
                  var PRO_PRE_VAL= await this.CalculateTaxPrecioValor(element)
                  element.precio = PRO_PRE_VAL.precio;
                  element.valor = PRO_PRE_VAL.valor;
                  this.Totales.totalGeneral.itbis += VALOR_IMPUESTO
                }

                if (element.productos.incluirimpuestoenprecio == false) {
                  if (element.productos.impuesto != null) {
                    var VALOR_IMPUESTO= this.RedondeoValor(await this.CalculateTax(element)/tasaMoneda);
                       element.valorimpuesto= VALOR_IMPUESTO
                       var PRO_PRE_VAL= await this.CalculateTaxPrecioValor(element)
                       element.precio = PRO_PRE_VAL.precio;
                       element.valor = PRO_PRE_VAL.valor;
                    this.Totales.totalGeneral.itbis += VALOR_IMPUESTO
                  }
                }
                this.Totales.totalGeneral.descuento += this.RedondeoBasico(element.DescValor)
                this.Totales.totalGeneral.recargo += element.RecarValor
                Ccobertura += element.cobertura
              } else {

                this.Totales.totalGeneral.subtotal += this.RedondeoBasico(element.valor);
                element.precioXcantidadNt=this.RedondeoBasico(element.valor/ tasaMoneda)
                if (element.productos.incluirimpuestoenprecio == true) {
                  if (element.productos.impuesto != null) {
                    var VALOR_IMPUESTO= this.RedondeoValor(await this.CalculateTax(element)/tasaMoneda);
                       element.valorimpuesto= VALOR_IMPUESTO
                       var PRO_PRE_VAL= await this.CalculateTaxPrecioValor(element)
                       element.precio = PRO_PRE_VAL.precio;
                       element.valor = PRO_PRE_VAL.valor;
                      this.Totales.totalGeneral.itbis += VALOR_IMPUESTO
                  }
                }
                if (element.productos.incluirimpuestoenprecio == false) {
                  if (element.productos.impuesto != null) {
                    var VALOR_IMPUESTO= this.RedondeoValor(await this.CalculateTax(element)/ tasaMoneda);
                       element.valorimpuesto= VALOR_IMPUESTO
                       var PRO_PRE_VAL= await this.CalculateTaxPrecioValor(element)
                       element.precio = PRO_PRE_VAL.precio;
                       element.valor = PRO_PRE_VAL.valor;
                    this.Totales.totalGeneral.itbis += VALOR_IMPUESTO
                  }
                }

                this.Totales.totalGeneral.descuento += this.RedondeoBasico(element.DescValor)
                this.Totales.totalGeneral.recargo += this.RedondeoBasico(element.RecarValor)
              }
            } else {

              if (element.productos.incluirimpuestoenprecio == true) {
                if (element.productos.impuesto != null) {
                  // var precioVar = element.precio    element.precioOrig
                   var precioVar =  JSON.parse(JSON.stringify(element.precioDig))
                  this.Totales.totalGeneral.subtotal += (element.cantidad * precioVar)
                  element.precioXcantidadNt=this.RedondeoValor((element.cantidad * precioVar)/ tasaMoneda)
                  var VALOR_IMPUESTO= await this.CalculateTax(element);
                       element.valorimpuesto= VALOR_IMPUESTO
                       var PRO_PRE_VAL= await this.CalculateTaxPrecioValor(element)
                       element.precio = PRO_PRE_VAL.precio;
                       element.valor = PRO_PRE_VAL.valor;
                  this.Totales.totalGeneral.itbis += VALOR_IMPUESTO
                }

                if (element.productos.incluirimpuestoenprecio == false) {
                  if (element.productos.impuesto != null) {
                    var VALOR_IMPUESTO= this.RedondeoValor(await this.CalculateTax(element)/ tasaMoneda);
                       element.valorimpuesto= VALOR_IMPUESTO
                       var PRO_PRE_VAL= await this.CalculateTaxPrecioValor(element)
                       element.precio = PRO_PRE_VAL.precio;
                       element.valor = PRO_PRE_VAL.valor;
                    this.Totales.totalGeneral.itbis += VALOR_IMPUESTO
                  }
                }
                this.Totales.totalGeneral.descuento += this.RedondeoBasico(element.DescValor)
                this.Totales.totalGeneral.recargo += this.RedondeoBasico(element.RecarValor)
                Ccobertura += element.cobertura
              } else {

                this.Totales.totalGeneral.subtotal += element.valor;
                element.precioXcantidadNt=this.RedondeoValor(element.valor/tasaMoneda)
                if (element.productos.incluirimpuestoenprecio == true) {
                  if (element.productos.impuesto != null) {
                    var VALOR_IMPUESTO= this.RedondeoValor(await this.CalculateTax(element)/ tasaMoneda);
                       element.valorimpuesto= VALOR_IMPUESTO
                       var PRO_PRE_VAL= await this.CalculateTaxPrecioValor(element)
                       element.precio = PRO_PRE_VAL.precio;
                       element.valor = PRO_PRE_VAL.valor;
                    this.Totales.totalGeneral.itbis += VALOR_IMPUESTO
                  }
                }
                if (element.productos.incluirimpuestoenprecio == false) {
                  if (element.productos.impuesto != null) {
                    var VALOR_IMPUESTO= this.RedondeoValor(await this.CalculateTax(element)/ tasaMoneda);
                       element.valorimpuesto= VALOR_IMPUESTO
                       var PRO_PRE_VAL= await this.CalculateTaxPrecioValor(element)
                       element.precio = PRO_PRE_VAL.precio;
                       element.valor = PRO_PRE_VAL.valor;
                    this.Totales.totalGeneral.itbis += VALOR_IMPUESTO
                  }
                }

                this.Totales.totalGeneral.descuento += this.RedondeoBasico(element.DescValor)
                this.Totales.totalGeneral.recargo += this.RedondeoBasico(element.RecarValor)
              }
            }
            var VALOR_NETO = await this.calcularValorNeto(element)
             element.valorneto= VALOR_NETO 
          }

          // OJO CON ESTO EN LA OFERTA
          resolve(element)
          // setTimeout(() => {resolve(element);}, 300);
        })()
         
        });
    },




    // Obtener los datos de la base de datos y mostrarlos en una tabla.
  getExistProdXAlmacen(e) {
if (!e.hasOwnProperty("unidadesProductosPK")) {
  return
}

this.descProductoExistencia=e.productos.descripcion.trim()
this.dialogExistenciaProducto=true
if (this.UsarLote == false) {
  this.ListaProdxAlmacen = []
  var obj = {
    unidadProducto: JSON.parse(JSON.stringify(e)),
  }

  this.$axios
    .post(this.$hostname + this.$hName + "/producto/ProductoAlmacExist", obj, {
      headers: this.$store.getters.GetheadersAxios,
    })
    .then((res) => {
      this.ListaProdxAlmacen = res.data

    })
    .catch((error) => { console.log('Catch---') });
}
if (this.UsarLote == true) {
  this.ListaProdxLote = []

  this.$axios
    .post(this.$hostname + this.$hName + "/producto/ProductoLoteExist", e.productos, {
      headers: this.$store.getters.GetheadersAxios,
    })
    .then((res) => {

      var ObjList = res.data
      var ObjProRow = ObjList.filter((ee) => ee.fechaVencimiento.codigo == e.lote);

      if (ObjProRow.length > 0) {
        this.ListaProdxLote = ObjList
        var _this = this;
        setTimeout(function () {
          _this.SelectorListProdXlote(ObjProRow[0])
        }, 800);
      } else {

        var Intlote = 0
        if (e.lote.length == 0) {
          Intlote = 0
        } else {
          Intlote = e.lote
        }

        this.$axios.get(this.$hostname + this.$hName + "/unidades-productos/findFecha/" + Intlote, {
          headers: this.$store.getters.GetheadersAxios,
        })
          .then((res) => {
            this.ListaProdxLote = ObjList
            var oobj = {
              balance: 0,
              codigo: res.data.codigo,
              fechaVencimiento: { codigo: res.data.codigo, fecha: res.data.fecha }
            }
            this.ListaProdxLote.push(oobj)
            var _this = this;
            setTimeout(function () {
              _this.SelectorListProdXlote(oobj)
            }, 800);

          })
          .catch((error) => { console.log('Catch---') });
      }

    })
    .catch((error) => { console.log('Catch---') });
}

    },

    //Para Mostrar la tabla existencia
    SelectorListProdXlote(e) {
      var ObjProRow = this.ListaProdxLote.filter((ee) => ee.fechaVencimiento.codigo == e.fechaVencimiento.codigo);
      this.selectedListProdxLote = ObjProRow
    },
 
//  Busca y preparar la lista de precio
  async buscarListaPrecioAutom(producto){
    var e =JSON.parse(JSON.stringify(producto))
    return new Promise((resolve, reject) => {
      (async () => {


if(this.headerFactura.activarListasPrecios==true){

  var CODPRO=e.unidadesProductosPK.producto
  var CODUNI=e.unidadesProductosPK.unidad
  var CODLISPRE=this.headerFactura.listaPrecio.id
  //cuando se agrege
 if(!e.hasOwnProperty('editando')){
    // CODLISPRE=this.headerFactura.listaPrecio.id
  const result =  await this.findListaprecioUnidaProducto(CODPRO,CODUNI,CODLISPRE);
 /* // const resUniPro =*/ 
  // const resUniPro =  await this.findUnidaProducto(CODPRO,CODUNI);
  if(result!=null){
    e.precio=result.precio
    e.precioDig=result.precio
    e.precioOrig=e.precio
  }
}

// cuando se cambie la lista de precio
if(e.hasOwnProperty('editando') && e.hasOwnProperty('changeListaPrecio')){
  const result =  await this.findListaprecioUnidaProducto(CODPRO,CODUNI,CODLISPRE);
  if(result!=null){
    e.precio=result.precio
    e.precioDig=result.precio
    e.precioOrig=e.precio
  }
  delete e["changeListaPrecio"]
}
}
        resolve(e)
      })()
    });
   },

   findListaprecioUnidaProducto(codpro,coduni,codlispre){

return  new Promise((resolve, reject) => {
var VARLINK=`findListaprecioUnidaProducto/${codpro}/${coduni}/${codlispre}`
  this.$axios.get(this.$hostname + this.$hName + '/unidades-productos/' + VARLINK, { headers: this.$store.getters.GetheadersAxios })
  .then(res => { 
    if(res.data.hasOwnProperty('id')){
      resolve(res.data)
    } else{
      resolve(null)
    }
    //  EventBus.$emit("loading", false);
     
  })
  .catch(error => {
      // EventBus.$emit("loading", false);
      resolve(null)
    });  

  });

   },

   async findUnidaProducto(codpro,coduni){
return  new Promise((resolve, reject) => {
var VARLINK=`${codpro}/${coduni}`
EventBus.$emit("loading", true);
  this.$axios.get(this.$hostname + this.$hName + '/unidades-productos/find/'+ VARLINK, { headers: this.$store.getters.GetheadersAxios })
  .then(res => { 
    resolve(res.data)
     EventBus.$emit("loading", false); 
  })
  .catch(error => {
      EventBus.$emit("loading", false);
      resolve(null)
    });  

  });

   },

   KeydownTabDescuento(e){
     //key: "Tab "keyCode: 9
    if(e.keyCode==9){
        this.AddProducto()
    }
   },


 // Comprobando si el valor seleccionado es nulo o indefinido.
 SelctCbVendedor() {

var _this = this;
setTimeout(function () {
  if (_this.vendedorSelect == null) {
    _this.vendedorSelect = "";
  }

  if (!_this.vendedorSelect.hasOwnProperty('codigo')) {
    _this.vendedorSelect = "";
    _this.vendedorSelect = null;
    _this.blurTextFilVendedorCodigo()
    return;
  }
  _this.blurTextFilVendedorCodigo()
}, 300);

},

cargarVendedor() {
      this.$axios
        .get(this.$hostname + this.$hName + "/vendedores/findallactiv", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.RVendedor = res.data.filter((ee) => this.headerFactura.sucursal==null || this.headerFactura.sucursal!=null&& this.headerFactura.sucursal.codigo==this.cajaSelect.sucursal.codigo);
           this.RVendedor = res.data
        }
        
        
        );
    },

    SalvarVendedores() {
      if (this.vendedorSelect != null) {
        this.blurTextFilVendedorCodigo();
      }
    },

  blurTextFilVendedorCodigo(){
  this.activoF7=false
    var _this = this;
        setTimeout(function () {
          _this.$refs.RefComboxBuscaProduc.focus();
        }, 500);

},




    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
          this.aalert.right = true
          this.aalert.top = false
        // this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
          this.aalert.right = false
        this.aalert.top = true
        EventBus.$emit("loading", false);
        this.ejecucion = true;
      }
    },

RedireccionFocus(e) {
      var _this = this;
      setTimeout(function () { _this.$refs[e].focus() }, 300)
    },

    RedireccionBlur(e) {
      var _this = this;
      setTimeout(function () { _this.$refs[e].blur() }, 300)
    }, 

  },
  computed: { 
    GetEjecucion() {
      if(this.modofactura==='salvarNuevo'){
    this.editando=false
    this.modificando=true
   }
   if(this.modofactura==='editar'){
    this.editando=true
    this.modificando=false
   }

   if(this.modofactura==='modificar'){
    this.editando=true
    this.modificando=true
   }


     },

},
};
</script>
<style lang="scss">
</style>
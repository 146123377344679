<template>
 <div> {{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
   <tr max-height="48px" height="48px">   

<ContenidoBase 
  :titulo="'Movimiento Productos'"
  :descripcion="'Movimiento Productos'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"  
/>
    </tr>
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitMovimientoProductos'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <div class="px-2" >
  <v-card color="grey lighten-3">

  <div class="px-2" >
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              
<v-col  cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 pt-2" > 

<v-row class="pa-0 ma-0 py-0 my-0" >

<v-col cols="12" md="6" sm="4" class="pa-0 ma-0 px-2 py-1">         
<v-combobox                   
                        autocomplete="off"
                        dense
                        outlined
                        label="Productos:"
                        :items="Rproducto"
                        item-text="codProDesc"
                        @keyup="CbFilProducto"
                        @blur="SelctCbProduc"
                        v-model="MovimientoProductos.producto"
                       @focus="CargarRproducto" 
                        style="font-size:13px;"
                        hide-details
                        @change="BuscarFiltros"
                        no-filter
                        
                      >
                      <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codProDesc = `${item.item.productos.codigo} - ${item.item.productos.descripcion.trim()} ${item.item.unidades.descripcion.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.productos.codigo}}</b> - {{item.item.productos.descripcion.trim()}} <b>{{ item.item.unidades.descripcion.trim()}}</b> </span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre" v-if="item.hasOwnProperty('productos')"> <b>{{ item.productos.codigo}}</b> - {{item.productos.descripcion}} {{item.unidades.descripcion}}  </span>
                  </template>
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-shopping-cart"
                          ></i>
                        </template>             
  </v-combobox>
</v-col>

<v-col cols="12" md="3" sm="4" class="pa-0 ma-0 px-2 py-1">         
<v-text-field
        dense
        outlined
        type="date"  
        label="Fecha desde"
         :rules="[$rules.required]"
        v-model="MovimientoProductos.desde"
        hide-details
         style="font-size:13px;"
         @blur="BlurDesde"
         @input="BuscarFiltros"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
</v-col>

<v-col cols="12" md="3" sm="4" class="pa-0 ma-0 px-2 py-1">         
<v-text-field
        dense
        outlined
        type="date"  
        label="Fecha Hasta"
         :rules="[$rules.required]"
        v-model="MovimientoProductos.hasta"
        hide-details
        style="font-size:13px;"
        @blur="BlurHasta"
        @input="BuscarFiltros"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
</v-col>

<v-col cols="12" md="5" sm="5" class="pa-0 ma-0 px-2 py-1">  
<v-combobox 
                    v-model="MovimientoProductos.almacen"
                    label="Almacén"
                    required
                    outlined
                    dense
                    autocomplete="off"
                    :items="Ralmacen"
                    item-text="descripcion"
                    @blur="SelctCbAlmacen"
                    @focus="CargarRalmacen"   
                     style="font-size:13px;"
                     hide-details
                     @change="BuscarFiltros"
                  >
                    <template v-slot:prepend>
                      <v-img
                        height="20px"
                        width="20px"
                        src="../assets/warehouse.png"
                      ></v-img>
                    </template>
 </v-combobox>
</v-col>
</v-row>

</v-col>
              
 <v-row>

<v-col cols="12" md="12" sm="12">
 <div style="border: 1px solid #000000;" >
<v-data-table
      class="elevation-1 RowSelectColor TbSize13"
      :headers="[
                { text: 'Fecha', sortable: false,  value: 'fecha', width:'auto', class: ['no-gutters black--text']  },
                 { text: 'Documento', sortable: false, value: 'Documento', width:'auto', class: ['no-gutters black--text']  },
                 { text: 'Costo RD$', sortable: false, value: 'Costo', width:'auto', class: ['no-gutters black--text']  },
                 { text: 'Precio RD$', sortable: false, value: 'Precio', width:'auto', class: ['no-gutters black--text']  },
                 { text: 'Entrada', sortable: false, value: 'Entrada', width:'auto', class: ['no-gutters black--text']  },
                 { text: 'Salida', sortable: false, value: 'Salida', width:'auto', class: ['no-gutters black--text']  },
                 { text: 'Existencia', sortable: false, value: 'Existencia', width:'auto', class: ['no-gutters black--text']  },
                 
                 ]"
              dense
              :items="listDatos"
              :search="search"      
              :items-per-page="15"
            >

<template v-slot:item.fecha="props">
    <span>{{FormatoFecha(props.item.fecha) }}</span>
</template>

<template v-slot:item.Costo="props">
    <span  >{{currencyFormatter(props.item.Costo )}}</span>
</template>

<template v-slot:item.Precio="props">
    <span  >{{currencyFormatter(props.item.Precio )}}</span>
</template>

<template v-slot:item.Entrada="props">
    <span  >{{currencyFormatter(props.item.Entrada )}}</span>
</template>

<template v-slot:item.Salida="props">
    <span  >{{currencyFormatter(props.item.Salida )}}</span>
</template>


<template v-slot:body.append v-if="windowSize.width >600">
<tr>  
<td class="border-top-bottom"  ><strong></strong></td>

<td class="border-top-bottom"  ><strong class="d-flex justify-end" style="font-size:15px; color: #00838F;">Total:</strong></td>                                                                               
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.costo)}} </strong></td>
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.precio)}} </strong></td>
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.entrada)}} </strong></td>
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.salida)}} </strong></td>
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(existenciaVal)}} </strong></td>

 
 </tr>
 </template>

<template v-slot:footer v-if="windowSize.width<=600">
<v-list-item style="font-size: 70%;" class="white--text" > 
      <v-list-item-content>
        <span>Total Costo: {{totales.costo}}</span>
        <span>Total Precio: {{totales.precio}}</span>
        <span>Total Entrada: {{totales.entrada}}</span>
       <span>Valor Salida: {{totales.salida}}</span>
       <span>Valor Existencia: {{existenciaVal}}</span>
      </v-list-item-content>
</v-list-item>

  </template>


</v-data-table>

 </div>
</v-col>

              </v-row>

            </v-form>
       </div>





  </v-card>
 </div>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../event-bus";
import BarraBusqueda2 from "../components/BarraBusquedaDinam";
import ContenidoBase from "../components/ContenidoBase";
import {currencyFormatter,FormatoFecha,HoraFormatter} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase

  },

  created() {
    var json = {
      titulo: "Movimiento Productos",
      descripcion: "Movimiento Productos",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      btnBarraDi:false,
      
      accion: [
        "MovimientoProductosNew",
        "MovimientoProductosSave",
        "MovimientoProductosRemove",
        "MovimientoProductosAnular",
        "MovimientoProductosPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("MovimientoProductosNew", this.new);
    // EventBus.$on("MovimientoProductosSave", this.save);
    // EventBus.$on("MovimientoProductosRemove", this.remove);
    // EventBus.$on("MovimientoProductosAnular", this.remove);
    // EventBus.$on("MovimientoProductosPrintList", this.imprimirList);
    EventBus.$on("emitMovimientoProductos", this.datostabla);
    EventBus.$on("onResize", this.onResize);
  },

  data:()=>  ({


      Ralmacen:[],
      RDepartamento:[],
      RFabricante:[],
      Rproveedor:[],
      Rproducto:[], 
      listDatos:[],
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
      existenciaVal:0,
      MovimientoProductos: {
        producto:null,
        almacen:null,
        desde: new Date().toISOString().substr(0,10),
        hasta:new Date().toISOString().substr(0,10),
      },

totales:{
  costo:0,
  precio:0,
  entrada:0,
  salida:0
},


EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },



     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
currencyFormatter,FormatoFecha,HoraFormatter,
onResize(e){
this.windowSize=e
},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    datostabla(e) {

      this.MovimientoProductos = e;
      this.acti_tb_data=false
    },
    save() { },
    remove() {},
    eliminar() { },
    anular() {},
    imprimirList() {},
    newwnuevo() {
      (this.MovimientoProductos = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },






CargarRproducto(){
 this.$axios.get(this.$hostname +this.$hName +
      "/unidades-productos/*/false/buscar",
      {headers:this.$store.getters.GetheadersAxios}
    ).then(res => {this.Rproducto = res.data }  );
},

CbFilProducto(e) {
    if (e.target.value.length >= 0) {

 this.$axios
    .get(this.$hostname +
        this.$hName +
        "/unidades-productos/"+(e.target.value === "" ? "A" : e.target.value.trim().replaceAll('/', '*-S-*'))+"/false/buscar",
      {headers:this.$store.getters.GetheadersAxios}
    )
    .then(res => {this.Rproducto = res.data });
     }
    },


 SelctCbProduc() {


 if (this.MovimientoProductos.producto == null) {
        this.MovimientoProductos.producto = "";
      }

      if (this.MovimientoProductos.producto.productos == undefined) {
        this.alerta("Seleccione un producto", "error");
        this.MovimientoProductos.producto = "";
        return;
      }

    },

CargarRalmacen() {
   this.$axios
    .get(this.$hostname + this.$hName + "/almacen/activos", 
    {headers:this.$store.getters.GetheadersAxios} 
    )
    .then(res => {
      this.Ralmacen = res.data;
    });

    },

 SelctCbAlmacen() {
      if (this.MovimientoProductos.almacen== null) {
        this.MovimientoProductos.almacen = "";
      }

      if (this.MovimientoProductos.almacen.codigo == undefined) {
        this.alerta("Seleccione un Almacén", "error");
        this.MovimientoProductos.almacen = "";
        return;
      }
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },


BlurDesde(){
if(this.MovimientoProductos.desde.toString().length==0){
  this.MovimientoProductos.desde=new Date().toISOString().substr(0,10)
}

 },

 BlurHasta(){

if(this.MovimientoProductos.hasta.toString().length==0){
  this.MovimientoProductos.hasta=new Date().toISOString().substr(0,10)
}

 },


BuscarFiltros(){
  
 var f1=new Date(this.MovimientoProductos.desde).toISOString().substr(0,10)
 var f2=new Date(this.MovimientoProductos.hasta).toISOString().substr(0,10)

// var Obj={}

var pro="null"
var al="null"


if(this.MovimientoProductos.almacen==null){  return 0  }
if(this.MovimientoProductos.producto==null){ return 0 }
if(this.MovimientoProductos.almacen!=null){  al=this.MovimientoProductos.almacen.codigo  }
if(this.MovimientoProductos.producto!=null){ pro=this.MovimientoProductos.producto.productos.codigo}

// producto.productos.
// this.MovimientoProductos: {
//         producto:null,
//         almacen:null,
//         desde: new Date().toISOString().substr(0,10),
//         hasta:new Date().toISOString().substr(0,10),
//       },



EventBus.$emit("loading", true);
var link = `/ConsultaRapida/MovimientoProductos/${f1}/${f2}/${pro}/${al}`
this.$axios
        .get(this.$hostname + this.$hName +link,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{ 
          this.calculoMovimiento(res.data)
          console.log(res.data);
          EventBus.$emit("loading", false);
        })
        .catch(error => {this.alerta("Reporte no Impreso("+error+")", "error")});

},

async calculoMovimiento(e){

this.totales={
  costo:0,
  precio:0,
  entrada:0,
  salida:0
}
var val = 0;
if (e.length>0){
  val = e[0][6];
}
 var listaProduct = []
 this.existenciaVal=0
 this.listDatos=[]
// Fecha[1],Documento[2],Costo[7],Precio[8],Entrada[4],Salida[5],Existencia[6]
await e.forEach(elem => {
  
var obj={
fecha:elem[1],
Documento:elem[2],
Costo:elem[7],
Precio:elem[8],
Entrada:elem[4],
Salida:elem[5],
Existencia:elem[13]+(this.existenciaVal+=((elem[4])-elem[5]))
}


// dev[13] + consultas.existenciaVal(dev[6],dev[4],dev[5])}
//  (existenciaVal += exi + ent - sal);


listaProduct.push(obj)

this.totales.costo+=elem[7]
this.totales.precio+=elem[8]
this.totales.entrada+=elem[4]
this.totales.salida+=elem[5]

});


this.listDatos=listaProduct



},


    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {},


  },


computed:{

  ActDescBtn(){

this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 

  }

}

};
</script>

<style></style>

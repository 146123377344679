<template>
 <div>
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
 
<tr max-height="48px" height="48px"  >
      
<ContenidoBase 
  :titulo="'Reporte análisis de ventas de productos'"
  :descripcion="'Imprime el análisis de ventas de productos'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"
   
/>
    </tr>
    



    
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitReportesAnalisisVentasProductos'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>


      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 <!-- Formulario-Reporte analisis de ventas de productos -->

 <v-container >
  <v-card color="grey lighten-3">

<v-toolbar
        flat
        color="#BDBDBD"
        dense
        style="border: 1px solid #000000;"
      >
        
  
 <v-avatar tile  size="45"  >
      <img
        src="@/assets/iconos_micromarket/custom-reports.png"
        alt="Reporte"
      >
    </v-avatar>
<span class="font-weight-light" style="font-size: 20px;" > Reporte análisis de ventas de productos </span >

  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
   <!-- style="color:white;" -->
    <!-- <i class=""> </i> -->

        <!-- <v-spacer></v-spacer> -->
        <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrarmodalcuenta"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
      </v-toolbar>
           
<hr>

  <v-container style="border: 1px solid #000000;">
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>
<!-- columna 1 -->
<v-col cols="12" md="6" sm="6">


  <!-- -----------------------------  desde  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Desde:"
         :rules="[$rules.required]"
        v-model="AnalisisVentas.desde"
        @blur="BlurDesde"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>


<v-combobox
                    v-model="AnalisisVentas.almacen"
                    label="Almacén"
                    required
                    outlined
                    dense
                    autocomplete="off"
                    :items="Ralmacen"
                    item-text="descripcion"
                    @blur="SelctCbAlmacen"
                    @focus="CargarRalmacen"
                  >
                    <template v-slot:prepend>
                      <v-img
                        height="20px"
                        width="20px"
                        src="../../assets/warehouse.png"
                      ></v-img>
                    </template>
                  </v-combobox>
                  <v-combobox                 
                        autocomplete="off"
                        dense
                        outlined
                        label="Departamento:"
                        :items="RDepartamento"
                        item-text="descripcion"
                        v-model="AnalisisVentas.departamento" 
                        hide-details 
                        @focus="cargarDepartamento"
                        @blur="SelctCbDesc"
                       
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-building"
                          ></i>
                        </template>
                      </v-combobox>




</v-col>
<!-- fn columna 1 -->


<!-- columna 2 -->
<v-col cols="12" md="6" sm="6"> 

<!-- -----------------------------  Hasta  -->
<v-text-field
        dense
        outlined
        type="date" 
         :rules="[$rules.required]" 
        label="Hasta:"
        v-model="AnalisisVentas.hasta"
        @blur="BlurHasta"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>


<!-------------------------------------------------- Producto -->
<v-combobox                    
                        autocomplete="off"
                        dense
                        outlined
                        label="Productos:"
                        :items="Rproducto"
                        item-text="productos.descripcion"
                        @keyup="CbFilProducto"
                        v-model="AnalisisVentas.producto"
                        @blur="SelctCbProduc"
                       @focus="CargarRproducto">

                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-shopping-cart"
                          ></i>
                        </template> 
                      </v-combobox>
       <v-combobox                 
                        autocomplete="off"
                        dense
                        outlined
                        label="Fabricante:"
                        :items="RFabricante"
                        item-text="nombre"
                        v-model="AnalisisVentas.fabricante"  
                        @focus="CargarFabricante"
                        @blur="SelctCbFabricante"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-wrench"
                          ></i>
                        </template>
                      </v-combobox>



</v-col>
<!-- fn columna 2 -->


              
              </v-row>


            </v-form>
       </v-container>

<hr>
<ModalReporteFiltros
@DataReporteBasico="DDataReporteBasico"
:Datos="AnalisisVentas"
/>


  </v-card>
 </v-container>
 <!-- fn Formulario-Reporte analisis de ventas de productos -->
        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros2";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha
} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros
  },
  created() {
    var json = {

      titulo: "Reporte análisis de ventas de productos",
      descripcion: "Imprime el análisis de ventas de productos",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      accion: [ 
        "ReportesAnalisisVentasProductosNew",
        "ReportesAnalisisVentasProductosSave",
        "ReportesAnalisisVentasProductosRemove",
        "ReportesAnalisisVentasProductosAnular",
        "ReportesAnalisisVentasProductosPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("ReportesAnalisisVentasProductosNew", this.new);
    // EventBus.$on("ReportesAnalisisVentasProductosSave", this.save);
    // EventBus.$on("ReportesAnalisisVentasProductosRemove", this.remove);
    // EventBus.$on("ReportesAnalisisVentasProductosAnular", this.remove);
    // EventBus.$on("ReportesAnalisisVentasProductosPrintList", this.imprimirList);
    EventBus.$on("emitReportesAnalisisVentasProductos", this.datostabla);
    this.cargarTodo();

    EventBus.$on("onResize", this.onResize);
  },

  data:()=>  ({

      Rpropietario:[],
      RSucursal:[],
      RCaja:[],
      Ralmacen:[],
      Rproducto:[],
      RDepartamento:[],
      RFabricante:[],

      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
      AnalisisVentas: {
        
        nombre:'REPORTE ANÁLISIS DE VENTAS DE PRODUCTOS',
        UrlImprimir: '/reportes-pv/ImprimirListAnalisisVentasProductos', 
        UrlExel: '/reportes-pv/ExcelListAnalisisVentasProductos', 
        UrlCorreo: '/reportes-pv/CorreoListAnalisisVentasProductos',
        desde: fechaNueva(),
        hasta: fechaNueva(),
        almacen:null,
        producto:null,
        departamento:null,
        fabricante:null

        //fechaCreacion: new,
      //  fechaModificacion: "", 
      },

      EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },

      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,

ver(){

console.log('Ver ver ver')

var jsn={
   usuario:{userid:"bibbia",passwd:"",jdbcJDNI:"",sudo:true,
                      dashboard:true,micromarket:false,transportnet:false,gubernamental:false,verVentasCaja:false},
   datos:JSON.parse(JSON.stringify(this.registro)), 
  }

this.$axios.post('http://10.0.0.95:8080/ReportServicios/services/reportes/findallUsuario',
            JSON.parse(JSON.stringify(jsn)))
           //  .then(res => this.alerta(res.data.mensage,res.data.estado))
            .then(res => {this.alerta("Dato Guardado correctamente","bien"), console.log(res.data)})
             .catch(error => this.alerta(error,'error'))


},



onResize(e){

console.log("imprii ")

console.log(e)
this.windowSize=e


},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    async cargarTodo() {
      
    },
    datostabla(e) {

      this.AnalisisVentas = e;
      this.acti_tb_data=false
    },
    save() { },
    remove() { },
    eliminar() {},
    anular() {},
    imprimirList() {},
    //retorna los datos del componente ModalReporteFiltros
    DDataReporteBasico(){},

    
SelctCbFabricante(){
 if(this.AnalisisVentas.fabricante==null){

    this.AnalisisVentas.fabricante=''
  }

if(this.AnalisisVentas.fabricante.codigo==undefined){

// this.alerta("Seleccione un fabricante", "error");
this.AnalisisVentas.fabricante=''
return

}

},


CargarFabricante(){
this.$axios
          .get(
              this.$hostname + this.$hName + "/fabricante/activo",
              {
                headers: this.$store.getters.GetheadersAxios
              }
          ).then(res => this.RFabricante = res.data)
          .catch()
},
    newwnuevo() {
      (this.AnalisisVentas = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },



// Realizar una solicitud GET al servidor y devolver los datos al Vue HTML.
CargarRalmacen() {


this.$axios
    .get(this.$hostname + this.$hName + "/almacen/activos", 
    {headers:this.$store.getters.GetheadersAxios} 
    )
    .then(res => {
      this.Ralmacen = res.data;
    });

    },

// Comprobando si el valor de la selección es nulo o indefinido.
 SelctCbAlmacen() {
      if (this.AnalisisVentas.almacen== null) {
        this.AnalisisVentas.almacen = "";
      }

      if (this.AnalisisVentas.almacen.codigo == undefined) {
        this.alerta("Seleccione un Almacén", "error");
        this.AnalisisVentas.almacen = "";
        return;
      }
    },



   // Comprobando si el producto es nulo o indefinido.
    SelctCbProduc() {
      if (this.AnalisisVentas.producto == null) {
        this.AnalisisVentas.producto = "";
      }

      if (this.AnalisisVentas.producto.productos == undefined) {
        this.alerta("Seleccione un producto", "error");
        this.AnalisisVentas.producto = "";
        return;
      }
    },


// Hacer una llamada API al servidor y obtener los datos.
 CargarRproducto(){

 this.$axios.get(this.$hostname +this.$hName +
      "/unidades-productos/*/false/buscar",
      {headers:this.$store.getters.GetheadersAxios}
    ).then(res => {this.Rproducto = res.data }  );

},


// Realizar una llamada API al servidor y devolver los datos al componente Vue.
CbFilProducto(e) {
      //console.log(e.target.value)
        if (e.target.value.length >= 3) {

 this.$axios
    .get(this.$hostname +
        this.$hName +
        "/unidades-productos/"+e.target.value.trim().replaceAll('/', '*-S-*')+"/false/buscar",
      {headers:this.$store.getters.GetheadersAxios}
    )
    .then(res => {this.Rproducto = res.data });
     }
    },



// Establecer la fecha a la fecha de hoy si el usuario no ha ingresado una fecha.
BlurDesde(){

if(this.AnalisisVentas.desde.toString().length==0){
  this.AnalisisVentas.desde=this.fechaNueva()
}

 },

// Establecer la fecha a la fecha de hoy si el usuario no ha ingresado una fecha.
 BlurHasta(){

if(this.AnalisisVentas.hasta.toString().length==0){
  this.AnalisisVentas.hasta=this.fechaNueva()
}

 },

 
cargarDepartamento(){

this.$axios
         .get(
             this.$hostname + this.$hName + "/departamento/activo",
             {
               headers: this.$store.getters.GetheadersAxios
             }
         ).then(res => this.RDepartamento = res.data)
         .catch(error => {
           if (error.response) {
             this.alerta(error.response.request.responseText, 'error')
           } else {
             this.alerta(error, 'error')
           }
         });
},
SelctCbDesc() {
      // producto:null,
      //     departamento:null,

      if (this.AnalisisVentas.departamento == null) {
        this.AnalisisVentas.departamento = "";
      }

      if (this.AnalisisVentas.departamento.codigo == undefined) {
        //this.alerta("Seleccione un departamento", "error");
        this.AnalisisVentas.departamento = "";
        return;
      }
    },



    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },




    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {

    }
  }
};
</script>

<style></style>

<template>
 <div> {{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
   <tr max-height="48px" height="48px">   

<ContenidoBase 
  :titulo="'Utilitario Productos'"
  :descripcion="'Utilitario Productos.'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"  
/>
    </tr>
    
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitUtilitarioProductos'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central -------------------------------------------------------------------------------->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <div class="px-2"  >
  <v-card color="grey lighten-3" >

  <div class="px-2" style="border: 1px solid #000000;">
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>

<v-col  cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0" > 
 <v-row class="pa-0 ma-0 py-0 my-0">
   <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 px-2 pb-2 ">
     <h4></h4>
   </v-col>
 <v-col cols="12" md="6" sm="6" class="pa-0 ma-0 px-2 pb-2 ">
  <!-- -----------------------------  almacen  -->
 <!-- @change="BuscarFiltros" -->
  <v-tooltip top>
<template v-slot:activator="{ on }"> 
<v-combobox v-on="on"
                    v-model="UtilitarioProductos.almacen"
                    label="Almacén"
                    required
                    outlined
                    dense
                    autocomplete="off"
                    :items="Ralmacen"
                    item-text="descripcion"
                    @blur="SelctCbAlmacen"
                    @focus="CargarRalmacen" 
                    hide-details  
                    v-on:change="resertPaginacion"
                    @change="getProducto"
                    :disabled="Reparaloading"
                  >
                    <template v-slot:prepend>
                      <v-img
                        height="20px"
                        width="20px"
                        src="../assets/warehouse.png"
                      ></v-img>
                    </template>
 </v-combobox>
</template>
 <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> 
          <span> Se registra el almacén que deseamos filtrar en la búsqueda.</span>
    </i>   
</v-tooltip>
 <!-- -----------------------------  Letra Incial  -->
 <v-tooltip top>
<template v-slot:activator="{ on }"> 

<v-text-field v-on="on"
            @input="getProducto"
            v-on:input="resertPaginacion"
            label="Letra Incial:"
            dense
            maxlength="1"
            autocomplete="off"
            outlined
            :rules="[$rules.required]"
            v-model="UtilitarioProductos.desdeletra"
            :disabled="Reparaloading"
          >
            <template v-slot:prepend>
              <i  style="font-size: 20px;" class="fa fa-sort-alpha-desc"></i>
            </template>
          </v-text-field>

 </template>
 <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> 
          <span> Se registra la letra incial que deseamos filtrar en la búsqueda.</span>
    </i>   
</v-tooltip>

</v-col>

<v-col cols="12" md="6" sm="6" class="pa-0 ma-0 px-2 pb-2">
 <!-- @change="BuscarFiltros" -->
 <!-- Productos -->
  <v-tooltip top>
<template v-slot:activator="{ on }"> 
  <v-combobox v-on="on"                  
                        autocomplete="off"
                        dense
                        outlined
                        label="Productos:"
                        :items="Rproducto"
                        item-text="codProDesc"
                        @keyup="CbFilProducto"
                        @blur="SelctCbProduc"
                        v-model="UtilitarioProductos.producto"
                       @focus="CargarRproducto" 
                       hide-details
                       @change="getProducto"
                       v-on:change="resertPaginacion"
                       :disabled="Reparaloading"
                       no-filter
                      >
                      <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codProDesc = `${item.item.productos.codigo} - ${item.item.productos.descripcion.trim()} ${item.item.unidades.descripcion.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.productos.codigo}}</b> - {{item.item.productos.descripcion.trim()}} <b>{{ item.item.unidades.descripcion.trim()}}</b> </span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre" v-if="item.hasOwnProperty('productos')"> <b>{{ item.productos.codigo}}</b> - {{item.productos.descripcion}} {{item.unidades.descripcion}}  </span>
                  </template>
                        <template v-slot:prepend>
                          <i
                          @click="productoClick"
                            style="font-size: 20px;"
                            class="fa fa-shopping-cart"
                          ></i>
                        </template>            
  </v-combobox>
</template>
 <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> 
          <span> Se registra el producto que deseamos filtrar en la búsqueda.</span>
    </i>   
</v-tooltip>

<v-tooltip top>
<template v-slot:activator="{ on }"> 

<v-text-field v-on="on"
            label="Letra Final:"
            maxlength="1"
            dense
            autocomplete="off"
            outlined
            :rules="[$rules.required]"
            v-model="UtilitarioProductos.hastaletra"
            @input="getProducto"
            v-on:input="resertPaginacion"
            :disabled="Reparaloading"
          >
            <template v-slot:prepend>
              <i  style="font-size: 20px;" class="fa fa-sort-alpha-asc"></i>
            </template>
          </v-text-field>
 </template>
 <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> 
          <span> Se registra la letra final que deseamos filtrar en la búsqueda.</span>
    </i>   
</v-tooltip>


</v-col>

</v-row>
</v-col>
<v-row>
<!-- fn columna 1 -->  

<v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 pt-2">
  
 <div class="px-2">
  <v-card>
 
 <v-toolbar
         flat
         color="indigo"
         dense
       >
         
 <v-card-actions   > 
 
 <v-tooltip top>
 <template v-slot:activator="{ on }">
    
 <v-btn id="boton-mini"  :disabled="Reparaloading"  rounded v-on="on" @click.prevent="recalcularBalance" color="blue" >
 <i style="font-size: 20px;" class="fa fa-retweet"></i><!-- fab x-small dark  -->
 recalcular</v-btn>
 
 
  </template>
           <span>Recalcular Balance</span>
   </v-tooltip>
 

 <v-tooltip top v-if="false">
 <template v-slot:activator="{ on }">
    
 <v-btn id="boton-mini" :disabled="Reparaloading"  rounded v-on="on" @click.prevent="repararproducto" color="blue lighten-3" >
 <i style="font-size: 20px;" class="fa fa-wrench"></i><!-- fab x-small dark  -->
 Restaurar</v-btn>
 
 
  </template>
           <span>Restaurar Producto</span>
   </v-tooltip>
 
 
 <v-tooltip top>
 <template v-slot:activator="{ on }">
    
 <v-btn id="boton-mini" :disabled="Reparaloading"  rounded v-on="on" @click.prevent="apostrofeproducto" color="cyan lighten-3" >
 <i style="font-size: 20px;" class="fa fa-opencart"></i><!-- fab x-small dark -->
 Apóstrofe</v-btn>
 
 
  </template>
           <span>Quita los Apóstrofes del producto</span>
   </v-tooltip>
 
 
 
   <v-tooltip top>
 <template v-slot:activator="{ on }">
    
 <v-btn id="boton-mini" :disabled="Reparaloading"  rounded v-on="on" @click.prevent="mayusculaproducto" color="teal lighten-4" >
  <v-avatar
  color="grey darken-4"
  size="20">
  <!-- <span class="white--text text-h5">a</span> -->
  <span style="font-size: 15px;" class="white--text text-h5">M</span>
</v-avatar>
 <!-- <i style="font-size: 20px;" class="fa fa-adn"></i> fab x-small dark  -->
 mayúscula</v-btn>
 
 
  </template>
           <span>coloca mayúscula a la descripción del producto</span>
   </v-tooltip>
 
   <v-tooltip top>
 <template v-slot:activator="{ on }">
    
 <v-btn id="boton-mini" :disabled="Reparaloading"  rounded v-on="on" @click.prevent="minusculaproducto" color="cyan lighten-4" >

  <v-avatar
  color="grey darken-4"
  size="20">
  <!-- <span class="white--text text-h5">a</span> -->
  <i style="font-size: 15px;" class="white--text text-h5 fa fa-maxcdn"></i>
</v-avatar>
 <!--<i style="font-size: 20px;" class="fa fa-amazon"></i>  fab x-small dark  -->


 Minúscula</v-btn>
 
 
  </template>
           <span>coloca minúscula a la descripción del producto</span>
   </v-tooltip>
 



   <v-tooltip top>
 <template v-slot:activator="{ on }">
 <v-btn id="boton-mini"  :disabled="Reparaloading"  rounded v-on="on" @click.prevent="dialogPorcientoDescuentoMax=true" color="teal lighten-4" >
 <i style="font-size: 20px;" class="fa fa-percent"></i><!-- fab x-small dark  -->
  descuento máximo
</v-btn>
  </template>
           <span> % descuento máximo</span>
</v-tooltip>

<v-tooltip top>
 <template v-slot:activator="{ on }">
 <v-btn id="boton-mini"  :disabled="Reparaloading"  rounded v-on="on" @click.prevent="dialogActPrecFecV=true" color="teal lighten-4" >
 <i style="font-size: 20px;" class="fa fa-file-archive-o"></i><!-- fab x-small dark  -->
  Act. PREC y FEC.V
</v-btn>
  </template>
           <span>Actualizar "calcular precio" y "fecha vencimiento".</span>
</v-tooltip>


 
 </v-card-actions>  
 
         <v-spacer></v-spacer>

 </v-toolbar>
 
 <div style="border: 1px solid #000000;" >
  <v-data-table
 
       class="elevation-1 RowSelectColor TbSize13"
       :single-select="true"
       item-key="indx"
       :show-select="true"
       v-model="selected"
       @click:row="datosRowSelect"
       @item-selected="obtenerDatoDetFact"
       :headers="[{ text: 'Código', sortable: true,  value: 'codigo', width:90, class: ['no-gutters black--text'] },
                  { text: 'Producto', sortable: true, value: 'producto', width:350, class: ['no-gutters black--text']},
                  { text: 'Unidad', sortable: false, value: 'unidad', width:100, class: ['no-gutters black--text'] },
                  { text: 'Almacen', sortable: false, value: 'almacen', width:230, class: ['no-gutters black--text'] },
                  { text: 'Ubicación', sortable: true, value: 'ubicacion', width:150, class: ['no-gutters black--text'] },
                  { text: 'Exit', sortable: false, value: 'existencia', width:110, class: ['no-gutters black--text'] },
                  { text: 'Exit.Historico', sortable: false, value: 'existenciaHistorico', width:110, class: ['no-gutters black--text'] },
                  { text: 'Precio', sortable: false, value: 'precio', width:129, class: ['no-gutters black--text'] },
                  { text: 'Costo', sortable: false, value: 'costo', width:129, class: ['no-gutters black--text'] },
                  { text: 'Factor', sortable: false, value: 'factor', width:110, class: ['no-gutters black--text'] },
                  { text: 'beneficio', sortable: false, value: 'beneficio', width:110, class: ['no-gutters black--text'] },
                  
                  ]"
               dense
               :items="listRproduct"     
               @pagination="cargarPaginacion"
               :items-per-page="paginator.itemsPerPage"
               :server-items-length="TotalRegistros"
               v-if="resertPagi==true"
             >
             <template v-slot:item.action="{ item }"> 
             <!-- { text: 'Acción', sortable: false, value: 'action', width:'auto' }, -->
             <v-btn fab @click.prevent="VerReporte(item)" x-small color="yellow accent-4"><i style="font-size: 20px;" class="fa fa-eye"></i></v-btn> 
             <!-- <v-btn fab @click.prevent="deleFact(item)" x-small color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn> -->
            </template>
 
 
            <template v-slot:item.producto="props">
                 <span style="font-size:13px; display:none;">{{props.item.indx = listRproduct.indexOf(props.item)}}</span>
                 <span style="font-size:13px;">{{props.item.producto}}</span>
           </template>
 
           <template v-slot:item.almacen="props">
                 <span style="font-size:13px;">{{props.item.almacen}}</span>
           </template>
 
              <template v-slot:item.ubicacion="props">
                 <span style="font-size:13px;">{{props.item.ubicacion}}</span>
           </template>
 
 
            <template v-slot:item.precio="props">
                 <span >{{  currencyFormatter(props.item.precio)}}</span>
           </template>
 
            <template v-slot:item.costo="props">
                 <span >{{  currencyFormatter(props.item.costo)}}</span>
           </template>
 
  </v-data-table>
 
 </div>
 
 
 
  </v-card>

 </div>
</v-col>

              </v-row>

            </v-form>
       </div>





  </v-card>
 </div>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
 
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>


    <v-dialog
        v-model="Reparaloading"
        
        persistent
        width="300"
      >
        <v-card
          color="grey darken-2"
          dark
        >
          <v-card-text>
            Espere...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
    </v-dialog>


 <!-- ------------------------------------------------------ Modal- Porciento de descuento máximo-->
 <v-dialog transition="fab-transition" label="Modal Vale de caja" v-model="dialogPorcientoDescuentoMax" persistent max-width="55%"
      max-height="65%">

      <!--------------------- Creacion Vale de caja ------------------------------->
      <div>
        <!-- <div style="max-height:345px;" class="overflow-x-auto"></div> -->

        <v-toolbar dark color="indigo" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Porciento de descuento máximo
              </v-toolbar-title>
            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Porciento de descuento máximo: </span> TODOS LOS PRODUCTOS</i>
          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle></v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">

                  <v-btn color="grey darken-4" fab small @click="dialogPorcientoDescuentoMax=false, porcientoDescuentoMax=0">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
                </template>
                <span>CANCELAR: Retornar </span>
              </v-tooltip>
          </v-card-actions>
        </v-toolbar>
        <v-card>
          <!-- columna Importar Datos -->
          <v-col cols="12" md="12" sm="12">

            <v-container>
              <v-card color="grey lighten-3">
                <b :class="`d-flex justify-center`">Porciento de descuento máximo</b>
                <v-form v-model="valid" ref="formPorcientoDescuento" lazy-validation>
                  <!-- Row 1 Importar Datos     ------------------------------------------->

                  <v-row>
                    
                    <v-col cols="12" md="12" sm="12">
                      <!------------------------------------------ Descuento -->
                      <AutoNumeric :rules="[$rules.Numericc]" label="Descuento Máximo:" outlined dense v-model="porcientoDescuentoMax">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-percent"></i>
                        </template>
                      </AutoNumeric>

                      <div :class="`d-flex justify-center`">
                      <v-tooltip top>
                      <template v-slot:activator="{ on }">
                      <v-btn id="boton-mini"  :disabled="Reparaloading"  rounded v-on="on" @click.prevent="porcientoDescuentoMaximo()" color="teal lighten-4" >
                      <i style="font-size: 20px;" class="fa fa-percent"></i><!-- fab x-small dark  -->
                        descuento máximo
                      </v-btn>
                        </template>
                                <span> % descuento máximo</span>
                      </v-tooltip>
                      </div>

                    </v-col>

                  </v-row>
                  <!-- Row 2 Importar Datos ------------------------------------------->

                </v-form>
              </v-card>
            </v-container>
          </v-col>
          <!-- columna informacion Importar Datos -->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- Porciento de descuento máximo -->


<!-- ------------------------------------------------------ Modal- Actualizar "calcular precio" y "fecha vencimiento"-->
<v-dialog transition="fab-transition" label="Modal Vale de caja" v-model="dialogActPrecFecV" persistent max-width="55%"
      max-height="65%">

      <!--------------------- Creacion Vale de caja ------------------------------->
      <div>
        <!-- <div style="max-height:345px;" class="overflow-x-auto"></div> -->

        <v-toolbar dark color="indigo" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Actualizar "calcular precio" y "fecha vencimiento"
              </v-toolbar-title>
            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Actualizar "calcular precio" y "fecha vencimiento": </span> TODOS LOS PRODUCTOS</i>
          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle></v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">

                  <v-btn color="grey darken-4" fab small @click="dialogActPrecFecV=false">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
                </template>
                <span>CANCELAR: Retornar </span>
              </v-tooltip>
          </v-card-actions>
        </v-toolbar>
        <v-card>
          <!-- columna Importar Datos -->
          <v-col cols="12" md="12" sm="12">

            <v-container>
              <v-card color="grey lighten-3">
                <b :class="`d-flex justify-center`">Actualizar "calcular precio" y "fecha vencimiento"</b>
                <v-form v-model="valid" ref="formPorcientoDescuento" lazy-validation>
                  <!-- Row 1 Importar Datos     ------------------------------------------->

                  
                    
                    <v-col  class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                      <v-row>

                        <v-col  class="d-flex justify-center" cols="6" md="6" sm="6">
                          <v-switch class="mt-0" color="teal" label=""
                                v-model="calcularPrecio">
                                <template v-slot:label>
                                  <strong v-if="calcularPrecio == true"
                                    style="color:#000000; font-size:13px;">calcular precio: Si</strong>
                                  <strong v-if="calcularPrecio == false"
                                    style="color:#000000; font-size:13px;">calcular precio: No</strong>
                                </template>
                              </v-switch>
                        </v-col>

                        <v-col  class="d-flex justify-center" cols="6" md="6" sm="6">
                          <v-switch class="mt-0" color="teal" label=""
                                v-model="fechaVencimiento">
                                <template v-slot:label>
                                  <strong v-if="fechaVencimiento == true"
                                    style="color:#000000; font-size:13px;">fecha vencimiento: Si</strong>
                                  <strong v-if="fechaVencimiento == false"
                                    style="color:#000000; font-size:13px;">fecha vencimiento: No</strong>
                                </template>
                              </v-switch>
                        </v-col>

                      <v-col  class="pa-0 ma-0 py-0 my-0 d-flex justify-center" cols="12" md="12" sm="12">
                    
                      <v-tooltip top>
                      <template v-slot:activator="{ on }">
                      <v-btn id="boton-mini"  :disabled="Reparaloading"  rounded v-on="on" @click.prevent="ActPrecFecV()" color="teal lighten-4" >
                      <i style="font-size: 20px;" class="fa fa-retweet"></i><!-- fab x-small dark  -->
                      Actualizar
                      </v-btn>
                        </template>
                                <span> Actualizar</span>
                      </v-tooltip>
                      
                    </v-col>
                    </v-row>
                    </v-col>

                 
                  <!-- Row 2 Importar Datos ------------------------------------------->

                </v-form>
              </v-card>
            </v-container>
          </v-col>
          <!-- columna informacion Importar Datos -->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- Actualizar "calcular precio" y "fecha vencimiento" -->





<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>


<script>
import {currencyFormatter,FormatoFecha,HoraFormatter} from "@/js/Funciones.js";
import { EventBus } from "../event-bus";
import BarraBusqueda2 from "../components/BarraBusquedaDinam";
import ContenidoBase from "../components/ContenidoBase";
import AutoNumeric from "@/components/AutoNumericNuevo";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    AutoNumeric

  },

  created() {
    var json = {
      titulo: "Utilitario Productos",
      descripcion: "Utilitario Productos.",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      btnBarraDi:false,
      
      accion: [
        "UtilitarioProductosNew",
        "UtilitarioProductosSave",
        "UtilitarioProductosRemove",
        "UtilitarioProductosAnular",
        "UtilitarioProductosPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("UtilitarioProductosNew", this.new);
    // EventBus.$on("UtilitarioProductosSave", this.save);
    // EventBus.$on("UtilitarioProductosRemove", this.remove);
    // EventBus.$on("UtilitarioProductosAnular", this.remove);
    // EventBus.$on("UtilitarioProductosPrintList", this.imprimirList);
    EventBus.$on("emitUtilitarioProductos", this.datostabla);
    EventBus.$on("onResize", this.onResize);
  },

  data:()=>  ({

      windowSize:{width:"auto", height:"auto"},
      Ralmacen:[],
      Rproducto:[], 
      dialogPorcientoDescuentoMax:false,
      dialogActPrecFecV:false,
      calcularPrecio:false,
      fechaVencimiento:false,
      porcientoDescuentoMax:0,
      UtilitarioProductos: {
        almacen:null,
producto:null,
desdeletra:"",
hastaletra:"", 
HistoricoBalance:false
      },

  acti_tb_data: false,
BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,
valid: true,
text: "",
EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:true,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },
//--------
listRproduct:[],
selected:[], 
TotalRegistros: 1,

      paginator: {
        itemsPerPage: 15,
        pageStart: 1,
        page: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },

dialogReporte:false,
resertPagi:true,
Reparaloading:false,
dialogNewCliente:false,

telefono:"",
ejecucion: true,
disableInp:true,
clientEmail:"",
disableInp:false,




  //-------------



      
      list: [],
      
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
currencyFormatter,FormatoFecha,HoraFormatter,

onResize(e){
// this.windowSize=e
},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
    },
   
    datostabla(e) {

      this.UtilitarioProductos = e;
      this.acti_tb_data=false
    },
    save() { },
    remove() { },
    eliminar() { },
    anular() { },
    imprimirList() {},
    newwnuevo() {
      this.UtilitarioProductos= {
        almacen:null,
producto:null,
desdeletra:"",
hastaletra:"", 
HistoricoBalance:false
        // fechaCreacion: new,
        //  fechaModificacion: "", 
      }

     //   EventBus.$emit("actualizaBarraBusqueda2");
    },






CargarRproducto(){

  this.$axios.get(this.$hostname +this.$hName +
      "/unidades-productos/*/false/buscar",
      {headers:this.$store.getters.GetheadersAxios}
    ).then(res => {this.Rproducto = res.data }  );
},

CbFilProducto(e) {
     if (e.target.value.length >= 0) {

 this.$axios
    .get(this.$hostname +
        this.$hName +
        "/unidades-productos/"+(e.target.value === "" ? "A" : e.target.value.trim().replaceAll('/', '*-S-*')) +"/false/buscar",
      {headers:this.$store.getters.GetheadersAxios}
    )
    .then(res => {this.Rproducto = res.data });
     }
    },


 SelctCbProduc() {


 if (this.UtilitarioProductos.producto == null) {
        this.UtilitarioProductos.producto = "";
      }

      if (this.UtilitarioProductos.producto.productos == undefined) {
        this.alerta("Seleccione un producto", "error");
        this.UtilitarioProductos.producto = "";
        return;
      }

    },

CargarRalmacen() {
      this.$axios
    .get(this.$hostname + this.$hName + "/almacen/activos", 
    {headers:this.$store.getters.GetheadersAxios} 
    )
    .then(res => {
      this.Ralmacen = res.data;
    });

    },

 SelctCbAlmacen() {
  var _this = this;
      setTimeout(function () {
        if (_this.UtilitarioProductos.almacen == null) {
          _this.UtilitarioProductos.almacen = "";
        }

        if (!_this.UtilitarioProductos.almacen.hasOwnProperty('codigo')) {
          _this.UtilitarioProductos.almacen = "";
          _this.UtilitarioProductos.almacen = null;
          return;
        }
      }, 300);

    },





productoClick(){
console.log('--viendo--productoClick')

},
//--------------------------------------------------------------

recalcularBalance(){

this.Reparaloading=true


// var _this=this;
// setTimeout(function(){ _this.Reparaloading=false }, 9000);


var obj= JSON.parse(JSON.stringify(this.UtilitarioProductos))

  var link = ``
  this.$axios
        .post(this.$hostname + this.$hName +"/utilitarioproductos/calcularBalance/",obj,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{ 
          var _this=this;
          setTimeout(function(){ _this.Reparaloading=false, _this.getProducto() }, 900);
        })
        .catch(error => {
          this.Reparaloading=false
          this.alerta("("+error+")", "error")
        });




/*this.$axios.post(this.$hostname + this.$hName + "/services/utilitariosInvetarios/calcularBalance", 
       {
          // descripcion:"",
           usuario:JSON.parse(JSON.stringify(this.$user)),
           datos:JSON.parse(JSON.stringify(this.UtilitarioProductos))       
       })
       .then(res => {
        var _this=this;
        setTimeout(function(){ _this.Reparaloading=false, _this.getProducto() }, 900);
        // this.TotalRegistros = res.data.totalRegistros, 
        // this.list = res.data.Listreporte,
       });*/

},

repararproducto(){


this.Reparaloading=true

var obj= JSON.parse(JSON.stringify(this.UtilitarioProductos))

  var link = ``
  this.$axios
        .get(this.$hostname + this.$hName +"/utilitarioproductos/repararProductos",{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{ 
          var _this=this;
          setTimeout(function(){ _this.Reparaloading=false, _this.getProducto() }, 900);
        })
        .catch(error => {
          this.Reparaloading=false
          this.alerta("("+error+")", "error")
        });



// var _this=this;
// setTimeout(function(){ _this.Reparaloading=false }, 9000);

/*this.$axios.post(this.$hostname + this.$hName + "/services/utilitariosInvetarios/repararProductos", 
       {
         
          // descripcion:"",
           usuario:JSON.parse(JSON.stringify(this.$user)),
           datos:JSON.parse(JSON.stringify(this.UtilitarioProductos))       
       })
       .then(res => {


         var _this=this;
        setTimeout(function(){ _this.Reparaloading=false, _this.getProducto() }, 3000);
        
        // this.TotalRegistros = res.data.totalRegistros, 
        // this.list = res.data.Listreporte,
        

       });*/


},





mayusculaproducto(){


this.Reparaloading=true


this.Reparaloading=true
var obj= JSON.parse(JSON.stringify(this.UtilitarioProductos))
  var link = ``
  this.$axios
        .get(this.$hostname + this.$hName +"/utilitarioproductos/mayusculaProducto",{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{ 
          var _this=this;
          setTimeout(function(){ _this.Reparaloading=false, _this.getProducto() }, 900);
        })
        .catch(error => {
          this.Reparaloading=false
          this.alerta("("+error+")", "error")
        });





// var _this=this;
// setTimeout(function(){ _this.Reparaloading=false }, 9000);

/*this.$axios.post(this.$hostname + this.$hName + "/services/utilitariosInvetarios/mayusculaProducto", 
       {
         
          // descripcion:"",
           usuario:JSON.parse(JSON.stringify(this.$user)),
           datos:JSON.parse(JSON.stringify(this.UtilitarioProductos))       
       })
       .then(res => {


         var _this=this;
        setTimeout(function(){ _this.Reparaloading=false, _this.getProducto() }, 3000);
        
        // this.TotalRegistros = res.data.totalRegistros, 
        // this.list = res.data.Listreporte,
        

       });*/


},

minusculaproducto(){


this.Reparaloading=true


this.Reparaloading=true
var obj= JSON.parse(JSON.stringify(this.UtilitarioProductos))
  var link = ``
  this.$axios
        .get(this.$hostname + this.$hName +"/utilitarioproductos/minusculaProducto",{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{ 
          var _this=this;
          setTimeout(function(){ _this.Reparaloading=false, _this.getProducto() }, 900);
        })
        .catch(error => {
          this.Reparaloading=false
          this.alerta("("+error+")", "error")
        });

},



apostrofeproducto(){


this.Reparaloading=true
var obj= JSON.parse(JSON.stringify(this.UtilitarioProductos))
  var link = ``
  this.$axios
        .get(this.$hostname + this.$hName +"/utilitarioproductos/apostrofeProducto",{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{ 
          var _this=this;
          setTimeout(function(){ _this.Reparaloading=false, _this.getProducto() }, 900);
        })
        .catch(error => {
          this.Reparaloading=false
          this.alerta("("+error+")", "error")
        });


// var _this=this;
// setTimeout(function(){ _this.Reparaloading=false }, 9000);

/*this.$axios.post(this.$hostname + this.$hName + "/services/utilitariosInvetarios/apostrofeProducto", 
       {
         
          // descripcion:"",
           usuario:JSON.parse(JSON.stringify(this.$user)),
           datos:JSON.parse(JSON.stringify(this.UtilitarioProductos))       
       })
       .then(res => {


         var _this=this;
        setTimeout(function(){ _this.Reparaloading=false, _this.getProducto() }, 3000);
        
        // this.TotalRegistros = res.data.totalRegistros, 
        // this.list = res.data.Listreporte,
         

       }); */


},



porcientoDescuentoMaximo(){

  if (this.$refs.formPorcientoDescuento.validate()) {

    console.log('Descuento-->',this.porcientoDescuentoMax)
    
    this.Reparaloading=true
var obj= JSON.parse(JSON.stringify(this.UtilitarioProductos))
  var link = ``
  this.$axios
        .get(this.$hostname + this.$hName +`/utilitarioproductos/porcientoDescuentoMaximo/${this.porcientoDescuentoMax}`,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{ 
          var _this=this;
          setTimeout(function(){ _this.Reparaloading=false }, 900);
        })
        .catch(error => {
          this.Reparaloading=false
          this.alerta("("+error+")", "error")
        });

  }else {
  this.alerta("Seleccione un archivo ", "error");
}


},

ActPrecFecV(){

if (this.$refs.formPorcientoDescuento.validate()) {

  console.log('Descuento-->',this.porcientoDescuentoMax)
  
  this.Reparaloading=true
var obj= JSON.parse(JSON.stringify(this.UtilitarioProductos))
var link = ``
this.$axios
      .get(this.$hostname + this.$hName +`/utilitarioproductos/ActualizarCalcularprecioFechavencimiento/${this.calcularPrecio}/${this.fechaVencimiento}`,{headers:this.$store.getters.GetheadersAxios})
      .then(res =>{ 
        var _this=this;
        setTimeout(function(){ _this.Reparaloading=false }, 900);
      })
      .catch(error => {
        this.Reparaloading=false
        this.alerta("("+error+")", "error")
      });

}else {
this.alerta("Seleccione un archivo ", "error");
}


},



resertPaginacion(){
this.resertPagi=false
this.paginator.page=1
this.paginator.pageStart=0
this.paginator.pageStop=this.paginator.itemsPerPage
var _this=this;
setTimeout(function(){ _this.resertPagi=true  }, 500);
//setTimeout(this.resertPagi=true,1500);
},

cargarPaginacion(e) {

this.selected=[]
   
       this.paginator = e;

      if (this.paginator.itemsPerPage == -1) {
           this.paginator.itemsPerPage = 15;
         // this.paginator.itemsPerPage.push(15) 

      }



this.getProducto()
   //   this.filtro();
    },


getProducto(){

var obj= JSON.parse(JSON.stringify(this.UtilitarioProductos))
obj.limit=this.paginator.itemsPerPage
obj.ultimo=this.paginator.pageStart

  var link = ``
  this.$axios
        .post(this.$hostname + this.$hName +"/utilitarioproductos/listadoProdutos/",obj,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{ 
          this.listRproduct = res.data.ListProducto,
         this.TotalRegistros=res.data.TotalRegistros
        })
        .catch(error => {this.alerta("("+error+")", "error")});

},

obtenerDatoDetFact(e){

//this.selected=[]

//this.selected.push(JSON.parse(JSON.stringify(e)))

},


datosRowSelect(e){

console.log('Ver objecto')
console.log(e)


this.selected=[]

this.selected.push(JSON.parse(JSON.stringify(e)))


},


//--------------------------------------------------------------






    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },




    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
          usuario:JSON.parse(JSON.stringify(this.$user)) 
        })
        .then(res => {
          this.list = res.data;
        });
    },

  },


computed:{

    ActDescBtn(){

this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 

    }
  }


};
</script>

<style></style>

<template>
  <div>
    {{ ActDescBtn }}
    <table style="position: absolute" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Código de Barra'" :descripcion="'Imprimir Etiquetas Código de Barra'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="remove" @MethodAnular="anular" @MethodImprimir="imprimirList"
          @MethodEtiquetaXFactura="EtiquetaXFactura" />
      </tr>

      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">
          <div class="overflow-y-auto" v-bind:style="{
            'max-height': this.windowSize.height - 96 + 'px',
            'max-width': '325px',
            width: '325px',
          }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle></v-btn-toggle>
                  <v-btn small width="95%" color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                    <i style="font-size: 25px" class="fa fa-eye"> </i>
                    ver
                  </v-btn>
                </center>
                <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitINVImprimirEtiquetas'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
              </template>
            </v-tooltip>
          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto" v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
            <div class="px-2">
              <v-card color="grey lighten-3">
                <center>
                  <h3>Imprimir Etiquetas Código de Barra</h3>
                </center>
                <div class="px-2">
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <!------------------------------------------- row 1 -->
                    <v-row style="margin-top: -10px">
                      <!------------------------------------------- columna 1 -->
                      <v-col cols="12" md="12" sm="12">
                        <!-------------------------------------------------- Producto -->
                        <v-combobox autocomplete="off" dense outlined label="Productos:" :items="Rproducto"
                          item-text="codProDesc" v-model="form.imprimirEtiqueta.producto"
                          @keyup="CbFilProducto" @blur="SelctCbProduc" @focus="CargarRproducto"  no-filter
                          @change="">
                          <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codProDesc = `${item.item.productos.codigo} - ${item.item.productos.descripcion.trim()} ${item.item.unidades.descripcion.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.productos.codigo}}</b> - {{item.item.productos.descripcion.trim()}} <b>{{ item.item.unidades.descripcion.trim()}}</b> </span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre" v-if="item.hasOwnProperty('productos')"> <b>{{ item.productos.codigo}}</b> - {{item.productos.descripcion}} {{item.unidades.descripcion}}  </span>
                  </template>
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-shopping-cart"></i>
                          </template>
                          <template v-slot:append-outer>
                            <v-tooltip v-if="false" top>
                              <template v-slot:activator="{ on }">
                                <v-btn @click.prevent="dialogCrearProducto = true" v-on="on" color="green" fab x-small
                                  dark>
                                  <i style="font-size: 20px" class="fa fa-plus-square"></i>
                                </v-btn>
                              </template>
                              <span>Agregar Producto</span>
                            </v-tooltip>
                          </template>
                        </v-combobox>
                      </v-col>
                    </v-row>
                    <!------------------------------------------- fn row 1 -->

                    <!------------------------------------------- row 2 -->
                    <v-row style="margin-top: -25px">
                      <v-col cols="12" md="4" sm="4">
                        <v-text-field label="Codigo" dense outlined v-model="form.imprimirEtiqueta.codigo"
                          :disabled="true">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-list-ol"></i>
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="4" sm="4">
                        <!-- ----------------------------- Código Barra -->
                        <v-text-field label="Código Barra:" autocomplete="off" dense outlined
                          v-model="form.imprimirEtiqueta.codigobarra" :disabled="true">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-barcode"></i>
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="4" sm="4">
                        <!-- ----------------------------- Factura-->

                        <v-text-field label="Factura" autocomplete="off" dense outlined
                          v-model="form.imprimirEtiqueta.factura">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-list-alt"></i>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <!------------------------------------------- fn row 2 -->

                    <v-row style="margin-top: -25px">
                      <v-col cols="12" md="4" sm="4">
                        <v-text-field type="number" label="Cantidad:" dense outlined :rules="[$rules.required]"
                          v-model.number="form.imprimirEtiqueta.cantidad">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-sort-amount-asc"></i>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" sm="4">
                        <v-text-field dense outlined type="date" label="Fecha vencimiento:"
                          v-model="form.imprimirEtiqueta.fecha" @blur="buscarLote">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" sm="4">
                        <v-combobox autocomplete="off" dense outlined label="suplidor:" :items="RSuplidor"
                          item-text="nombre" v-model="form.imprimirEtiqueta.suplidor" @blur="SelctCbSuplidor"
                          @focus="cargarSuplidor" @keyup="CbFilSuplidor">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-handshake-o"></i>
                          </template>
                        </v-combobox>
                      </v-col>
                    </v-row>
                    <v-row style="margin-top: -25px">
                      <v-col cols="12" md="3" sm="3">
                        <!-- ----------------------------- Unidad -->
                        <v-text-field label="Unidad" outlined dense v-model="form.imprimirEtiqueta.unidad" disabled>
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-tachometer"></i>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" sm="3">
                        <!-------------------------------------------  Beneficio -->
                        <AutoNumeric label="Beneficio:" dense outlined autocomplete="off"
                          v-model.number="form.imprimirEtiqueta.beneficio">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-usd"></i>
                          </template>
                        </AutoNumeric>
                      </v-col>
                      <v-col cols="12" md="3" sm="3">
                        <!-------------------------------------------------- costo -->
                        <AutoNumeric label="Costo:" dense outlined autocomplete="off"
                          v-model.number="form.imprimirEtiqueta.costo">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-usd"></i>
                          </template>
                        </AutoNumeric>
                      </v-col>
                      <v-col cols="12" md="3" sm="3">
                        <AutoNumeric label="Precio:" dense outlined autocomplete="off"
                          v-model.number="form.imprimirEtiqueta.precio">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-usd"></i>
                          </template>
                        </AutoNumeric>
                      </v-col>
                      <!------------------------------------------- fn columna 1 -->
                      <!------------------------------------------- columna 2 -->
                      <v-col cols="12" md="4" sm="4">
                        <v-combobox autocomplete="off" dense outlined label="Código de barra:" item-text="descripcion"
                          :items="listEtiquetas" v-model="form.imprimirEtiqueta.formatoBarra" @focus="cargarEtiquetas"
                          @blur="SelctCbEtiquetas">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-barcode"></i>
                          </template>
                        </v-combobox>

                        <!-------------------------------------------------- terminal -->
                        <!--:items="RSuplidor",@blur="SelctCbSuplidor", @focus="cargarSuplidor", @keyup="CbFilSuplidor" -->
                        <v-combobox autocomplete="off" dense outlined label="* Seleccione Impresora:" item-text="nombre"
                          :items="impList" @focus="cargarImpresoras" v-model="form.imprimirEtiqueta.Impresora">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-trademark"></i>
                          </template>
                        </v-combobox>

                        <!-------------------------------------------------- Impresora -->
                        <!--:items="RSuplidor",@blur="SelctCbSuplidor", @focus="cargarSuplidor", @keyup="CbFilSuplidor" -->
                      </v-col>

                      <v-col cols="12" md="4" sm="4">
                        <v-combobox autocomplete="off" dense outlined label="Monedas" :items="Rmoneda" item-text="descripcion"
                  :rules="[$rules.required]" v-model="objMoneda.moneda" style="font-size:13px;" @focus="cargarMoneda" @blur="SelctCbMoneda"
                  @change="getChangeMoneda"
                  id="formdisabledInput">
                  
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-money"></i>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span id="ImputColorDisable">
                      {{ item.descripcion }} ({{ item.nombrecorto }})
                    </span>

                  </template>


                  <template slot="item" slot-scope="item">
                    <!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
                    <v-col style="font-size: 13px;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                      <p class="pa-0 ma-0 py-0 my-0"><b>{{ item.item.descripcion }} ({{ item.item.nombrecorto }})</b>
                      </p>
                    </v-col>
                  </template>
                        </v-combobox>
                      </v-col>

                      <v-col cols="12" md="4" sm="4"  >
                        <v-text-field v-if="objMoneda.moneda != null" id="formdisabledInput" label="Tasa" dense outlined
                        v-model.number="objMoneda.tasa">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-usd"></i>
                        </template>
                        </v-text-field>
                      </v-col>


                    </v-row>
                  </v-form>
                </div>
              </v-card>
            </div>
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">
          <div class="overflow-y-auto" v-bind:style="{
            'max-height': this.windowSize.height - 96 + 'px',
            'max-width': '325px',
            width: '325px',
          }">
            <!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>
        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->
      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent max-width="100%" max-height="100%"
      transition="fab-transition">
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="">
                <i style="font-size: 25px" class="fa fa-print"> </i>
                Listado
              </v-btn>
            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color: #dd4b39" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table :headers="slotHtabla" dense :items="list" :search="search" @click:row="datostabla">
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog label="myAlert" v-model="Elim_notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn @click.prevent="
            eliminar(), (Elim_notif.estado = !Elim_notif.estado)
          " small>
            <i style="font-size: 20px" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%" :vertical="true" :timeout="120000"
      top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

    <!-- ------------------------------------------------------ Modal- crear Producto-->
    <v-dialog transition="fab-transition" label="Modal agregar producto" v-model="dialogCrearProducto" persistent
      max-width="90%" max-height="80%">
      <CrearProducto @EmitCrearProducto="RecCrearProducto" />
    </v-dialog>

    <!-- ------------------------------------------------------ Modal  Etiqueta-->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogEtiqueta" persistent max-width="90%"
      max-height="80%">
      <v-card>
        <v-toolbar flat color="indigo" dense>
          <i style="font-size: 20px" class=""> </i>
          <v-toolbar-title style="color: white" class="font-weight-light">
            Etiquetas X Factura</v-toolbar-title>
          <i style="font-size: 20px" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="dialogEtiqueta = false">
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br />

          <v-form ref="formEtiqueta" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <!-------------------------------------------------- suplidor -->
                <v-combobox autocomplete="off" dense outlined label="suplidor:" :items="RSuplidor" item-text="nombre"
                  v-model="imprimirEtiquetaXfact.suplidor" @blur="SelctCbSuplidorEtXfact" @focus="cargarSuplidor"
                  @keyup="CbFilSuplidor">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-handshake-o"></i>
                  </template>
                </v-combobox>

                <v-data-table dense :items-per-page="15" :headers="[
                  {
                    text: 'FECHA',
                    value: 'fecha',
                    sortable: false,
                    class: ['no-gutters'],
                  },
                  {
                    text: '#FACTURA',
                    value: 'factura',
                    sortable: false,
                    class: ['no-gutters'],
                  },
                  {
                    text: 'VALOR',
                    value: 'valor',
                    sortable: false,
                    class: ['no-gutters'],
                  },
                  {
                    text: 'ACCIÓN',
                    value: 'action',
                    sortable: false,
                    class: ['no-gutters'],
                  },
                ]" :items="imprimirEtiquetaXfact.RListFactura" item-key="codigo" class="elevation-1">
                  <template v-slot:item.fecha="props">
                    <span style="font-size: 13px">
                      {{ FormatoFecha(props.item.fecha) }}
                    </span>
                  </template>

                  <template v-slot:item.valor="props">
                    <span style="font-size: 13px">
                      {{ currencyFormatter(props.item.total) }}</span>
                  </template>

                  <template v-slot:item.factura="props">
                    <span style="font-size: 13px">
                      {{ props.item.referencia }}</span>
                  </template>

                  <template v-slot:item.action="{ item }">
                    <v-btn fab @click.prevent="ImprimirItemEtiquetaFActura(item)" x-small color="blue"><i
                        style="font-size: 20px" class="fa fa-print"></i></v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Etiqueta-->

    <!-- fn Modales ----------------------------------------------------------------------------------------------->
  </div>
</template>

<script>
import AutoNumeric from "../../components/AutoNumericNuevo";
import CrearProducto from "../../CuentaXPagar/UtilidadComponents/CrearProducto";
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import {
  currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  getParamFecha
} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    CrearProducto,
    AutoNumeric,
    ContenidoBase,
  },
  data() {
    return {};
  },
  created() {
    var json = {
      titulo: "Código de Barra",
      descripcion: "Imprimir Etiquetas Código de Barra",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "INVImprimirEtiquetasNew",
        "INVImprimirEtiquetasSave",
        "INVImprimirEtiquetasRemove",
        "INVImprimirEtiquetasAnular",
        "INVImprimirEtiquetasPrintList",
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("INVImprimirEtiquetasNew", this.new);
    EventBus.$on("INVImprimirEtiquetasSave", this.save);
    EventBus.$on("INVImprimirEtiquetasRemove", this.remove);
    EventBus.$on("INVImprimirEtiquetasAnular", this.remove);
    EventBus.$on("INVImprimirEtiquetasPrintList", this.imprimirList);
    EventBus.$on("emitINVImprimirEtiquetas", this.datostabla);
    this.cargarTodo();

    EventBus.$on("onResize", this.onResize);
    this.getMonedaBase()
  },

  data() {
    return {
      ListaTiposClientes: [],
      windowSize: { width: "auto", height: "auto" },

      dialogCrearProducto: false,
      dialogEtiqueta: false,
      listEtiquetas: [],
      imprimirEtiquetaXfact: {
        suplidor: null,
        RListFactura: [],
      },
      Rmoneda:[],
      objMoneda:{
        moneda:null,
        tasa:null
      },

      impList: [],
      Rproducto: [],
      RSuplidor: [],

      form: {
        imprimirEtiqueta: {
          codigo: 0,
          producto: null,
          fecha: null,
          factura: "",
          suplidor: null,
          costo: 0,
          beneficio: 0,
          precio: 0,
          codigoBarra: null,
          Impresora: null,
          formatoBarra: null,
          unidad: "",
          cantidad:0
        },
      },
      EstadoBtn: {
        BarraTablaDinamicaAprir: false,
        nuevo: true,
        guardar: false,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
        etiquetaXfactura: true,
      },

      text: "",
      valid: true,
      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:",
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" },
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?",
      },

      aalert: {
        estado: false,
        color: null,
      },
    };
  },

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    currencyFormatter,
    FormatoFecha,
    HoraFormatter,
    getParamFecha,
    onResize(e) {
      console.log("imprii ");

      console.log(e);
      this.windowSize = e;
    },

    RecCrearProducto(e) {
      this.dialogCrearProducto = e.estado;
    },

    cargarEtiquetas() {
      this.$axios
        .get(this.$hostname + this.$hName + "/etiquetas/findall", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.listEtiquetas = res.data));
    },
    cargarImpresoras() {
      var header = {
        mode: 'no-cors',
        header: {
          'Access-Control-Allow-Origin': '*',
          'origin': 'x-requested-with',
          'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
          'Content-Type': 'application/json'
        }
      }
      this.$axios
        .get("http://localhost:15999/impresora/inicial/listaImpresora", header)
        .then((res) => {
          if (res.data.length > 0) {
            this.impList = res.data[0].impresoras;
            //this.form.imprimirEtiqueta.Impresora = res.data[0].defaulPrinter;
          }
        });
    },

    SelctCbEtiquetas() {


      var _this = this;
      setTimeout(function () {
        if (_this.form.imprimirEtiqueta.formatoBarra== null) {
          _this.form.imprimirEtiqueta.formatoBarra= "";
        }

        if (!_this.form.imprimirEtiqueta.formatoBarra.hasOwnProperty('id')) {
          _this.form.imprimirEtiqueta.formatoBarra= "";
          _this.form.imprimirEtiqueta.formatoBarra= null;
            _this.alerta("Seleccione una Barra", "error")
          return;
        }
      }, 300);

     /* if (this.form.imprimirEtiqueta.formatoBarra == null) {
        this.form.imprimirEtiqueta.formatoBarra = "";
      }
      if (this.form.imprimirEtiqueta.formatoBarra.id == undefined) {
        this.alerta("Seleccione una Barra", "error");
        this.form.imprimirEtiqueta.formatoBarra = "";
        return;
      }*/
    },

    CbFilProducto(e) {
      if (e.target.value.length >= 0) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/unidades-productos/" +
            (e.target.value === "" ? "A" : e.target.value.trim().replaceAll('/', '*-S-*'))+
            "/false/buscar/true",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => (this.Rproducto = res.data));
      }
    },

    SelctCbProduc() {

 /*if (this.form.imprimirEtiqueta.producto == null) {
        this.form.imprimirEtiqueta.producto = "";
      }*/


      var _this = this;
      setTimeout(function () {
        if (_this.form.imprimirEtiqueta.producto== null) {
          _this.form.imprimirEtiqueta.producto= "";
        }
        //!_this.form.imprimirEtiqueta.producto.productos.hasOwnProperty('codigo')
        if (!_this.form.imprimirEtiqueta.producto.hasOwnProperty('productos')) {
          _this.alerta("Seleccione un producto", "error");
          _this.form.imprimirEtiqueta.producto= "";
          _this.form.imprimirEtiqueta.producto= null;
          //  _this.alerta("Seleccione un cliente", "error");
          return;
        }

        _this.form.imprimirEtiqueta.codigoBarra =
        _this.form.imprimirEtiqueta.producto.codigobarra;
        _this.form.imprimirEtiqueta.codigo =
        _this.form.imprimirEtiqueta.producto.productos.codigo;
        _this.form.imprimirEtiqueta.precio =
        _this.form.imprimirEtiqueta.producto.precio;
        _this.form.imprimirEtiqueta.costo =
        _this.form.imprimirEtiqueta.producto.costo;
        _this.form.imprimirEtiqueta.beneficio =
        _this.form.imprimirEtiqueta.producto.porbeneficio;
        _this.form.imprimirEtiqueta.unidad =
        _this.form.imprimirEtiqueta.producto.unidades.descripcion;

      }, 300);

      
    },

    cargarSuplidor() {
      this.$axios
        .get(
          this.$hostname + this.$hName + "/proveedores/findallbyDesc/25/0/*",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.RSuplidor = res.data

          console.log('--cargarSuplidor----')
          console.log(res.data)
        });
    },
    SelctCbSuplidor() {
      if (this.form.imprimirEtiqueta.suplidor == null) {
        this.form.imprimirEtiqueta.suplidor = "";
      }
      if (this.form.imprimirEtiqueta.suplidor.codigo == undefined) {
        this.alerta("Seleccione un proveedor", "error");
        this.form.imprimirEtiqueta.suplidor = "";
        return;
      }
    },
    buscarLote() {
      if (this.form.imprimirEtiqueta.fecha == null) {
        return;
      }
      if (this.$store.getters.GetConfiguracionGeneral.configInv.usarLote) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/unidades-productos/getLote/" + this.form.imprimirEtiqueta.fecha,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(
            (res) =>
              (this.form.imprimirEtiqueta.producto.lote = res.data)
          );

      }
    },
    SelctCbSuplidorEtXfact() {
      if (this.imprimirEtiquetaXfact.suplidor == null) {
        this.imprimirEtiquetaXfact.suplidor = "";
      }
      if (this.imprimirEtiquetaXfact.suplidor.codigo == undefined) {
        this.alerta("Seleccione un suyplidor", "error");
        this.imprimirEtiquetaXfact.suplidor = "";
        return;
      }
      this.findFacturas(this.imprimirEtiquetaXfact.suplidor.codigo);
    },

    findFacturas(proveedor) {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/facturasproveedor/findListFactProveedorPlus/15/0/null/" +
          proveedor +
          "/null/null/null",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(
          (res) =>
            (this.imprimirEtiquetaXfact.RListFactura = res.data.ListItems)
        );
    },

    CbFilSuplidor(e) {
      if (e.target.value.length >= 0) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/proveedores/findallbyDesc/25/0/" +
            (e.target.value === "" ? "*" : e.target.value),
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => (this.RSuplidor = res.data));
      }
    },

    CargarRproducto() {
      this.$axios
        .get(
          this.$hostname + this.$hName + "/unidades-productos/*/false/buscar/false",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => (this.Rproducto = res.data))
        .catch((er) => console.log(er));
    },

    NumeralFac(e) {
      if (e.key) {
        var mynumeral = require("numeral");
        this.form.imprimirEtiqueta.factura = mynumeral(
          this.form.imprimirEtiqueta.factura
        ).value();
        //console.log(mynumeral(""+this.form.imprimirEtiqueta.factura)).value()
        if (this.form.imprimirEtiqueta.factura == null) {
          this.form.imprimirEtiqueta.factura = 0;
        }
      } else {
        console.log("no se puede poner letras o signos");
      }
    },

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
    },
    async cargarTodo() { },
    datostabla(e) {
      this.unidades = e;
      this.acti_tb_data = false;
    },
    save() {
    },
    remove() {
      if (this.unidades.codigo != 0 && this.unidades.codigo != null) {
        this.Elim_notif.estado = !this.Elim_notif.estado;
      } else {
        this.alerta("Seleccione un tipo de cliente para eliminar", "error");
      }
    },
    eliminar() {
    },
    anular() {
    },

    imprimirList() {
      if (this.form.imprimirEtiqueta.cantidad <= 0) {
        this.alerta("Debe digitar la cantidad de etiqueta que desea imprimir!!", "error")
        return;
      }
      if (this.$store.getters.GetConfiguracionGeneral.configInv.usarLote) {
        if (this.form.imprimirEtiqueta.fecha == null) {
          this.alerta("Al estar usando lote debe registrar la fecha de vencimiento en cada etiqueta!!", "error")
          return;
        }
      }

      if (this.form.imprimirEtiqueta.formatoBarra == null) {
        this.alerta("Debe selecionar un formato de etiqueta", "error");
        return;
      }
      if (this.form.imprimirEtiqueta.Impresora == null) {
        this.alerta("Debe selecionar una impresora para imprimir", "error");
        return;
      }

// this.$axios.get(this.$hostname + this.$hName + "/unidades-productos/findUnidadMinimaCompra/" + this.form.imprimirEtiqueta.codigo + '/' + !this.form.imprimirEtiqueta.producto.unidadminima, { headers: this.$store.getters.GetheadersAxios })
      this.$axios.get(this.$hostname + this.$hName + "/unidades-productos/findUnidadMinimaCompra/" + this.form.imprimirEtiqueta.codigo + '/' + !this.form.imprimirEtiqueta.producto.unidadminima, { headers: this.$store.getters.GetheadersAxios })
        .then((res) => {
          this.form.imprimirEtiqueta.config = this.$store.getters.GetConfiguracionGeneral;
          this.form.imprimirEtiqueta.productoOpuesto = res.data;
          console.log('--------');
          console.log(this.form.imprimirEtiqueta);
          var Obj=JSON.parse(JSON.stringify(this.form.imprimirEtiqueta))
          Obj.producto=JSON.parse(JSON.stringify(this.form.imprimirEtiqueta.productoOpuesto))
          Obj.productoOpuesto=JSON.parse(JSON.stringify(this.form.imprimirEtiqueta.producto))
          Obj.monedaSelect={
            tasaSelect:this.objMoneda.tasa,
            modedacorto:this.objMoneda.moneda.nombrecorto.trim()
          }
          this.imprimir(Obj, "etiqueta");
        });

    },
    imprimir(datos, operacion) {
      EventBus.$emit("loading", true);
      datos.operacion = operacion;
      this.$axios
        .post("http://localhost:15999/impresora/imprimir", datos)
        .then((res) => {
          EventBus.$emit("loading", false);
          //this.limpiarPuntoVentaCompleto();
        })
        .catch((error) => {
          EventBus.$emit("loading", false);
          console.error(error)
        });

    },
    EtiquetaXFactura() {

      if (this.form.imprimirEtiqueta.cantidad <= 0) {
        this.alerta("Debe digitar la cantidad de etiqueta que desea imprimir!!", "error")
        return;
      }
      if (this.form.imprimirEtiqueta.formatoBarra == null) {
        this.alerta("Debe selecionar un formato de etiqueta", "error");
        return;
      }
      if (this.form.imprimirEtiqueta.Impresora == null) {
        this.alerta("Debe selecionar una impresora para imprimir", "error");
        return;
      }
      this.imprimirEtiquetaXfact.RListFactura = [];
      this.imprimirEtiquetaXfact.suplidor = null;
      this.dialogEtiqueta = true;
      console.log("Entrando a EtiquetaXFactura");
    },

    ImprimirItemEtiquetaFActura(e) {
      console.log(e);
      if (e != null) {
        e.facturasProveedoresDetalleList.forEach((element) => {
          if (element.productos.imprimirEtiqueta) {
            this.$axios
              .get(
                this.$hostname + this.$hName + "/unidades-productos/find/" + element.productos.codigo + "/" + element.unidades.codigo,
                { headers: this.$store.getters.GetheadersAxios }
              )
              .then((res) => {

                var UNI_MINI=null
                var bar = new Promise((resolve, reject) => {
                if(res.data.unidadminima==false){
                this.$axios.get(this.$hostname + this.$hName + "/unidades-productos/findUnidadMinimaCompra/"+element.productos.codigo+"/true" ,
                { headers: this.$store.getters.GetheadersAxios })
              .then((ress) => {
                UNI_MINI=ress.data
                resolve()
              });

                }else{
                  UNI_MINI=res.data
                  resolve()
                }                     
});


bar.then(() => {

                if (this.$store.getters.GetConfiguracionGeneral.configInv.usarLote) {

                  this.$axios
                    .get(
                      this.$hostname + this.$hName + "/unidades-productos/getLote/" + res.data.fechavencimiento,
                      { headers: this.$store.getters.GetheadersAxios }
                    )
                    .then((lote) => {
                      var resultado = {};
                      resultado.config = this.$store.getters.GetConfiguracionGeneral;
                      resultado.suplidor = this.imprimirEtiquetaXfact.suplidor;
                      resultado.cantidad = element.cantidad;
                      resultado.producto = res.data;
                      resultado.producto.lote = lote.data;
                      resultado.factura = e.referencia;
                      resultado.unidad = element.unidades.descripcion;
                      resultado.formatoBarra = this.form.imprimirEtiqueta.formatoBarra;
                      resultado.Impresora = this.form.imprimirEtiqueta.Impresora;
                      resultado.productoOpuesto = UNI_MINI;
                      this.imprimir(resultado, "etiqueta");
                    });
                } else {
                  var resultado = {};
                  resultado.config = this.$store.getters.GetConfiguracionGeneral;
                  resultado.suplidor = this.imprimirEtiquetaXfact.suplidor;
                  resultado.cantidad = element.cantidad;
                  resultado.producto = res.data;
                  resultado.factura = e.referencia;
                  resultado.unidad = element.unidades.descripcion;
                  resultado.formatoBarra = this.form.imprimirEtiqueta.formatoBarra;
                  resultado.Impresora = this.form.imprimirEtiqueta.Impresora;
                  resultado.productoOpuesto = UNI_MINI;
                  if(element.fechavencimiento!=null){
                    resultado.fecha=this.getParamFecha(element.fechavencimiento)
                  }
                  this.imprimir(resultado, "etiqueta");
                }
              });

              });
          }
        });
      }
    },

    newwnuevo() {
      (this.unidades = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: "",
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
      EventBus.$emit("loading", false);
    },


    getMonedaBase(){
var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
  this.objMoneda.moneda= MONEDA
  this.objMoneda.tasa=MONEDA.tasa
},

   cargarMoneda() {
      this.$axios
        .get(this.$hostname + this.$hName + "/monedas/findallActivos/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.Rmoneda = res.data));
    },
    SelctCbMoneda() {
var _this = this;
      setTimeout(function () {
        if (_this.objMoneda.moneda == null) {
          _this.objMoneda.moneda = "";
        }

        if (!_this.objMoneda.moneda.hasOwnProperty('codigo')) {
          _this.objMoneda.moneda = "";
          _this.objMoneda.moneda = null;
          _this.getMonedaBase()
          return;
        }
        //_this.objMoneda.tasa=_this.objMoneda.moneda.tasa
      }, 300);
},

  getChangeMoneda(e) {
      if ( typeof e === 'object' && e != null && e.hasOwnProperty('codigo') ) {
        this.objMoneda.tasa=e.tasa
      }
    
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
        EventBus.$emit("loading", false);
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() { },
  },

  computed: {
    ActDescBtn() {
      if (this.form.imprimirEtiqueta.codigo > 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: false,
          nuevo: true,
          guardar: false,
          eliminar: false,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          etiquetaXfactura: true,
        };
      }

      if (this.form.imprimirEtiqueta.codigo <= 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: false,
          nuevo: true,
          guardar: false,
          eliminar: false,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          etiquetaXfactura: true,
        };
      }
    },
  },
};
</script>

<style></style>

<template>
  <div v-if="statusFoms"  v-dirkeydown="keyESC" >{{ RedireccionFocus('RefTextFieldProducto') }}
    <v-card>
      
      <v-toolbar flat color="indigo" dense>

        <i style="font-size: 20px;" class=""> </i>
        <v-toolbar-title style="color:white;" class="font-weight-light"> PRODUCTOS </v-toolbar-title>
        <i style="font-size: 20px;" class=""> </i>

        <v-spacer></v-spacer>
        <v-btn color="grey darken-4" fab small @click="cerrarModalAddProd()">
          <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <span><b>Nota:</b><span style="color: #C62828;"> Cierra la ventana al presionar la tecla (ESC).</span></span>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-text-field ref="RefTextFieldProducto" autocomplete="off" v-on="on" v-model="Modallfiltro.producto" 
            @keydown="SelectMarcaProducto" @keyup="CargarListTb"
              @focus="CargarRproducto" label="Buscar" single-line hide-details>
              <template v-slot:prepend-inner>
                <i style="font-size: 30px;" class="fa fa-search"></i>
              </template>
            </v-text-field>
          </template>
          <span>Busqueda</span>
        </v-tooltip>

        <v-data-table dense @click:row="addClikProd" :footer-props="arrayPropiedases" 
        id="TbBord"
        class="elevation-1 RowSelectColor TbSize13"
        :headers="[
         {text: 'CODIGO', value: 'productos.codigo' ,sortable: false, class: ['no-gutters black--text']},
         {text: 'REFERENCIA', value: 'referencia' ,sortable: false, class: ['no-gutters black--text']},
         {text: 'FABRICANTE', value: 'fabricante' ,sortable: false, class: ['no-gutters black--text']},
         {text: 'PRODUCTO', value: 'productos.descripcion' ,sortable: false, class: ['no-gutters black--text']},
         {text: 'COSTO', value: 'costo' ,sortable: false, class: ['no-gutters black--text']},
         {text: 'PRECIO', value: 'precio' ,sortable: false, class: ['no-gutters black--text']},
         {text: 'EXISTENCIA(ALM-PRINCIPAL)', value: 'existencias' ,sortable: false, align:'center', class: ['no-gutters black--text']},     
        ]
        " :items="ListItems" item-key="codigo"  @pagination="cargarPaginacionAll"
          :items-per-page="TbPaginator.itemsPerPage" :page.sync="TbPaginator.page" :pageCount="TbPaginator.pageCount"
          :search="Modallfiltro.producto" :server-items-length="TbTotalItem">

          <!-- PRODUCTO, COSTO, PRECIO, EXISTENCIA -->

          <template v-slot:item.productos.codigo="props">
            <span style="display:none;">{{ props.item.indx = ListItems.indexOf(props.item) }} {{ props.item.id = ListItems.indexOf(props.item) }}</span>
            <v-col v-bind:style="{ 'font-size': '13px;', 'background': GetColorListProducto(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
              <span v-if="usarLote==false" style="font-size:13px;">{{props.item.productos.codigo }}</span>
            <span v-if="usarLote" style="font-size:13px;">{{props.item.lote }}</span>
          </v-col>
          </template>

          <template v-slot:item.productos.descripcion="props">
            <v-col v-bind:style="{ 'font-size': '13px;', 'background': GetColorListProducto(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
            <span style="font-size:13px;"> {{props.item.productos.descripcion }} <b>{{ props.item.unidades.descripcion }}</b> </span>
            </v-col>
          </template>

          <template v-slot:item.referencia="props">
            <v-col v-bind:style="{ 'font-size': '13px;', 'background': GetColorListProducto(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
            <span style="font-size:13px;" > {{ getReferenciaProducto(props.item)}}</span>
          
            </v-col>
          </template>

          <template v-slot:item.costo="props">
            <v-col v-bind:style="{ 'font-size': '13px;', 'background': GetColorListProducto(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
            <span style="font-size:13px;"> {{ currencyFormatter(props.item.costo)}}</span>
            </v-col>
          </template>

          <template v-slot:item.precio="props">
            <v-col v-bind:style="{ 'font-size': '13px;', 'background': GetColorListProducto(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
            <span style="font-size:13px;"> {{currencyFormatter(props.item.precio)}}</span>
            </v-col>
          </template>

          <template v-slot:item.existencias="props">
            <v-col v-bind:style="{ 'font-size': '13px;', 'background': GetColorListProducto(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
            <span style="font-size:13px;"> {{props.item.existencias}}</span>
            </v-col>
          </template>
          <template v-slot:item.fabricante="props">
            <v-col v-bind:style="{ 'font-size': '13px;', 'background': GetColorListProducto(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
            <span style="font-size:13px;" v-if="props.item.productos.hasOwnProperty('fabricante') && props.item.productos.fabricante!=null " > {{props.item.productos.fabricante.nombre.trim()}}</span>
            
            </v-col>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>


    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="2000" top="top">
      {{ aalert.nombre }}

      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

  </div>
</template>

<script>
import dirkeydown from '@/directives/dir-keydown';
import AutoNumeric from "../../components/AutoNumericNuevo";
import { EventBus } from "../../event-bus";
export default {

directives:{
    dirkeydown 
},
  components: {
    AutoNumeric
  },
  mounted() {
    //  this.CargarRproducto()

  },

  created() {

  },


  data() {

    return {
      search: '',
      ListItems: [],
      selectProducto:null,
      aalert: {
        estado: false,
        color: null
      },
      arrayPropiedases: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Cantidad de Registros Por Paginas',
        showFirstLastPage: false
      },
      Modallfiltro: {
        producto: null,
      },
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },
      usarLote:false
    }


  }, props: {
    unidadminima: {
      required: false,
      type: Boolean,
      default: false
    },

    statusFoms: {
      required: false,
      type: Boolean,
      default: false
    },


  },
  methods: {
    keyESC(e){
      //key:"ESC" 27
      if (e.keyCode ==27 ) {
        this.cerrarModalAddProd()
      }
    },

    CargarListTb() {
      var prod = ''
      if (this.Modallfiltro.producto == null || this.Modallfiltro.producto != null && this.Modallfiltro.producto.toString().length == 0) { prod = '*' } else {
        prod = JSON.parse(JSON.stringify(this.Modallfiltro.producto.trim().replaceAll('/', '*-S-*')))
      }

      this.usarLote = this.$store.getters.GetConfiguracionGeneral.configInv.usarLote
      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + prod + "/" + false + "/" + this.unidadminima + "/" + this.usarLote
      this.$axios.get(this.$hostname + this.$hName + '/unidades-productos/findProducto/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => { 
          this.ListItems = res.data.ListItems,
           this.TbTotalItem = res.data.TbTotalItem 
          })





    },



    cargarPaginacionAll(e) {
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()
      //this.TbTotalItem =685

    },


    Limpiarfiltro() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.Modallfiltro = {
        producto: null
      }

      this.ListItems = []
      this.TbTotalItem = 0
      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()


    },



    CargarRproducto() {
      this.selectProducto=null
     // this.Limpiarfiltro()
      //  console.log('Hola Mundo klk')
      //this.$store.getters.GetConfiguracionGeneral.config.monedabase.nombrecorto.trim()
      /* var url = "/unidades-productos//false/buscar";
       if (this.unidadminima) {
         url = "/unidades-productos//false/buscar/true";
       }
       this.$axios
         .get(
           this.$hostname +
           this.$hName + url,
           { headers: this.$store.getters.GetheadersAxios }
         )
         .then(res => { this.RallProducto = res.data });*/
    },



    CbFilProducto(e) {
      if (e.target.value.length >= 2) {
        var url = "/unidades-productos/" + e.target.value.trim().replaceAll('/', '*-S-*') + "/false/buscar";
        if (this.unidadminima) {
          url = "/unidades-productos/" + e.target.value.trim().replaceAll('/', '*-S-*') + "/false/buscar/true";
        }
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            url,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => (this.RallProducto = res.data));
      }
    },




    filtro() {




    },
    addClikProd(e) {

      this.alerta("Producto agregado", "bien")


     var BUSQAGIL= this.$store.getters.GetConfiguracionGeneral.configInv.busquedaProductoAgil

if(BUSQAGIL==true){
  this.Modallfiltro.producto=""
      this.selectProducto =null
      this.Limpiarfiltro()
}

      var Objt = {
        estado: "datos",
        datos: e
      }
      this.$emit("EmitAgregarProducto", Objt);
      this.RedireccionFocus('RefTextFieldProducto')
    },


    cerrarModalAddProd() {
      this.search = ""
      //this.CargarRproducto()
      // this.Limpiarfiltro()
      // this.statusFoms=false
      var Objt = { estado: "cerrar" }
      this.$emit("EmitAgregarProducto", Objt);

    },

    currencyFormatter(number) {
      //return this.formatNumber(params) + ".00";
      if (number == null) { number = 0 }
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");


      ///return this.formatNumber(params);

      //return params;
    },


    SelectMarcaProducto(e) {
        //ArrowRight:39
        if(e.keyCode == 39){
          if (this.ListItems.length != 0) {
            if(this.TbPaginator.pageStop!=this.TbTotalItem){
              this.TbPaginator.page=(this.TbPaginator.page+1)
            var _this = this;
        setTimeout(function () {
          _this.selectProducto = JSON.parse(JSON.stringify(_this.ListItems[0]));
           }, 500);
          return 0;

               }
             }
        }

        //ArrowLeft:37
        if(e.keyCode == 37){
          if (this.ListItems.length != 0) {
          if(this.TbPaginator.pageStart>0){
            this.TbPaginator.page=(this.TbPaginator.page-1)
            var _this = this;
        setTimeout(function () {
          _this.selectProducto = JSON.parse(JSON.stringify(_this.ListItems[0]));
           }, 500);
          return 0;
            }
        }
        }

       // ArrowUp/ArrowDown
       if(e.keyCode == 38 ||  e.keyCode == 40){
          if (this.selectProducto == null && this.ListItems.length != 0) {
          this.selectProducto = JSON.parse(JSON.stringify(this.ListItems[0]));
          return 0;
        }
        }

        if (this.selectProducto != null) {

          //key: "Enter
          if (e.keyCode == 13) {
            this.addClikProd(this.selectProducto)
          }

        //key: "ArrowUp"keyCode: 38
        if (e.keyCode == 38) {
            var VarInddx = JSON.parse(JSON.stringify(this.selectProducto.indx - 1));
            if (this.ListItems[VarInddx] != undefined) {
              this.selectProducto = JSON.parse(JSON.stringify(this.ListItems[VarInddx]));
            } else {
              this.selectProducto = JSON.parse(
                JSON.stringify(this.ListItems[VarInddx + 1])
              );
            }

            return 0;
          }

//key: "ArrowDown"keyCode: 40
if (e.keyCode == 40) {
            var VarInddx = JSON.parse(JSON.stringify(this.selectProducto.indx + 1));
            if (this.ListItems[VarInddx] != undefined) {
              this.selectProducto = JSON.parse(JSON.stringify(this.ListItems[VarInddx]));

            } else {
              this.selectProducto = JSON.parse(
                JSON.stringify(this.ListItems[VarInddx - 1])
              );
            }
            return 0;
          }

        }

    },



    GetColorListProducto(e){
    // wsedfksajdfkj mme quede aquii
   /* unidadesProductosPK.producto
unidadesProductosPK.unidad*/
    
  /**/
  var PRODUCTOSELEC=this.selectProducto
  if(PRODUCTOSELEC!=null && PRODUCTOSELEC.hasOwnProperty('indx') && PRODUCTOSELEC.indx== e.indx && PRODUCTOSELEC.unidadesProductosPK.producto ==e.unidadesProductosPK.producto && PRODUCTOSELEC.unidadesProductosPK.unidad ==e.unidadesProductosPK.unidad){
   // return '#FF9800'
   return '#64B5F6'
  }else{
    return 'transparent'
  }

  //me quede por aqui

  },

  getReferenciaProducto(e){
    var OBJ='.';

    if(e.productos.hasOwnProperty('referencia') && e.productos.referencia!=null && e.productos.referencia.trim().length>0){
      return e.productos.referencia.trim();
    }else{
      return OBJ;
    }

  },

  RedireccionFocus(e) {
      var _this = this;
      setTimeout(function () { _this.$refs[e].focus() }, 300)
    },



    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        //  this.NewCliente();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);


      }
    },








  },
  computed: {

    ejecFucComp(){
      this.activarFoms()
    }
  
  }


}
</script>

<style>

</style>